import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import { FcGoogle } from "react-icons/fc";
import AuthTitle from "../../../components/common/title/AuthTitle";
import Logo from "../../../components/common/logo";
import Input from "../../../components/form/Input";
import verificationIcon from "../../../assets/images/icons/verification-icon.png";
import AuthImageDark from "../../../assets/images/auth-image-dark.png";
import AuthImageLight from "../../../assets/images/auth-image-light.png";
import { loginWithGoogle, signUpWithEmail } from "../../../api/auth";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import { SignUpState } from "../interfaces";
import styles from "../auth.module.scss";
import { useGoogleLogin } from "@react-oauth/google";
import { AppDispatch, useAppDispatch } from "../../../features/store";
import { setUserData } from "../../../features/auth/accountSlice";

const { Helmet } = require("react-helmet");

const SignUp = () => {
  const hasToken = localStorage.getItem("access-token");
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useAppDispatch();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const validationScheme = useCreateSchema(["email", "password1", "password2"]);
  const [loading, setLoading] = useState(false);
  const [pendingVerification, setPendingVerification] = useState(
    location.state === "verificationEmailSent" ? true : false
  );
  const [initialValues, setInitialValues] = useState<SignUpState>({
    email: "",
    password1: "",
    password2: "",
  });

  const getSubmitHandler = () => async (values: SignUpState, formik: any) => {
    setLoading(true);
    const response = await signUpWithEmail(values);

    if (response) {
      setLoading(false);
      if (response.status === 201) {
        toast.success("Verification email sent");
        setPendingVerification(true);
      } else if ((response.status = 400)) {
        toast.error("It is not possible to register user with this data");
        if (response.data) {
          Object.entries(response.data).forEach(([field, errors]) => {
            //@ts-ignore
            errors.forEach((error: string) => {
              formik.setFieldError(
                field === "non_field_errors" ? "password1" : field,
                error
              );
            });
          });
        }
      }
    }
  };

  useEffect(() => {
    if (hasToken) {
      navigate("/analytics");
    }
  }, []);

  // @ts-ignore
  const googleResponseMessage = async (res) => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    const response = await loginWithGoogle(res);

    if (response.status === 200) {
      dispatch(setUserData(response.data));
      navigate(redirect ? `/${redirect}` : "/");
    } else if (response.status === 400) {
      const error_messsage = response.response.data?.non_field_errors?.at(0);
      if (
        error_messsage ===
        "User is already registered with this e-mail address."
      )
        toast.error(
          "You are already registered with this e-mail address. Please use email and password to authenticate."
        );
    } else {
      toast.error("Invalid login data");
    }
  };

  //@ts-ignore
  const googleErrorMessage = (error) => {
    toast.error("Invalid login data");
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: googleResponseMessage,
    onError: googleErrorMessage,
  });

  return (
    <Box className={styles.loginWrapper}>
      <Helmet>
        <title>Coinfident | Sign Up</title>
      </Helmet>
      <Logo isAuth />
      {pendingVerification ? (
        <Fade>
          <Box className={styles.verificationMessage}>
            <img src={verificationIcon} alt="verification-icon" />
            <Typography
              variant="body1"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              Verification email sent. <br /> Please check your inbox for
              confirmation.
            </Typography>

            <Typography
              mt={5}
              variant="body2"
              component="h4"
              align="center"
              sx={{
                //@ts-ignore
                color: theme.palette.common.mute,
              }}
            >
              Didn't receive an email?
            </Typography>
            <Link
              to="/resend-verification-email"
              style={{ color: theme.palette.primary.main }}
            >
              Resend Email
            </Link>
          </Box>
        </Fade>
      ) : (
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item md={6} xs={12}>
            <Fade triggerOnce>
              <AuthTitle margin="35px 0 30px">Sign Up</AuthTitle>
              <Formik
                initialValues={initialValues}
                validateOnMount={true}
                validationSchema={validationScheme}
                enableReinitialize={true}
                onSubmit={getSubmitHandler()}
              >
                {(formik) => (
                  <Form id="signup_form">
                    <Grid container justifyContent="center" spacing={3}>
                      <Grid item xs={12} sm={7}>
                        <Input name="email" formik={formik} label="Email" />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          name="password1"
                          formik={formik}
                          type="password"
                          label="Password"
                          helpText="Use at least  8 characters, 1 number and 1 uppercase letter"
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input
                          name="password2"
                          formik={formik}
                          type="password"
                          label="Repeat password"
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={7}>
                        <EarlyAccessCode />
                      </Grid> */}

                      <Grid item xs={12} sm={7}>
                        <PrimaryButton
                          text="Sign Up"
                          disabled={!formik.isValid}
                          loading={loading}
                          isFullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            className={styles.line}
                            sx={{ backgroundColor: theme.palette.text.primary }}
                          />
                          <Typography mx={1} color={theme.palette.text.primary}>
                            OR
                          </Typography>
                          <Box
                            className={styles.line}
                            sx={{ backgroundColor: theme.palette.text.primary }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <PrimaryButton
                          text="Sign Up With Google"
                          disabled={false}
                          loading={false}
                          isFullWidth
                          startIcon={<FcGoogle />}
                          clickHandler={() => handleGoogleLogin()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Box className={styles.linkWrapper}>
                          <Typography
                            variant="body2"
                            align="left"
                            sx={{ color: theme.palette.text.primary }}
                          >
                            Already have an account?
                          </Typography>
                          <Link
                            to="/login"
                            style={{ color: theme.palette.primary.main }}
                          >
                            Login
                          </Link>
                        </Box>
                        <Typography
                          variant="body2"
                          align="left"
                          sx={{
                            fontWeight: 300,
                            fontSize: "12px",
                            marginTop: "30px",
                            textAlign: "justify",
                            //@ts-ignore
                            color: theme.palette.common.whiteToGreen,
                          }}
                        >
                          By signing up I confirm that I have read, understood
                          and accept the terms of use, and consent to the
                          processing of my personal data as started in the
                          privacy policy
                        </Typography>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Fade>
          </Grid>
          {!downLg && (
            <Grid item md={6} xs={12} sx={{ height: "100%" }}>
              <Fade triggerOnce>
                <Box
                  className={styles.authImage}
                  sx={{
                    boxShadow:
                      theme.palette.mode === "dark"
                        ? `inset 10px 3px 45px rgba(255, 255, 255, 0.3)`
                        : `inset 10px 3px 45px rgba(0, 0, 0, 0.3)`,
                    background: `url(${
                      theme.palette.mode === "dark"
                        ? AuthImageDark
                        : AuthImageLight
                    })`,
                  }}
                ></Box>
              </Fade>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default SignUp;
