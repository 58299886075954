import { Box, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import icon_c from "../../assets/images/icons/icons8-cancel-96.png";
import icon_r from "../../assets/images/icons/icons8-check-mark-96.png";

interface IsRelevantProps {
  Icon: boolean;
}

export const IsRelevant: React.FC<IsRelevantProps> = ({ Icon }) => {
  const [iconUrl, setIconUrl] = useState("");
  const theme = useTheme();

  useEffect(() => {
    if (Icon) {
      setIconUrl(icon_r);
    } else {
      setIconUrl(icon_c);
    }
  }, [Icon]);

  return (
    <>
      <Box
        display="grid"
        alignContent="space-evenly"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <img src={iconUrl} alt="Relevant" />

        <Typography color={theme.palette.text.primary}>Is Relevant</Typography>
      </Box>
    </>
  );
};
