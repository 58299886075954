import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IoSend } from "react-icons/io5";
import { IoIosAttach } from "react-icons/io";
import { RootState } from "../../../features/store";
import styles from "../agents.module.scss";

const networks = [
  "Ethereum",
  "Polygon",
  "Bitcoin",
  "Bitcoincash",
  "Dogecoin",
  "Litecoin",
  "Stellar",
  "Xrp",
  "Solana",
  "Avalanche",
  "Fantom",
  "Optimism",
];
const unavailable_networks = ["Algorand", "Near", "Polkadot", "Tezos", "Tron"];

const ChatInput: React.FC<any> = ({
  isLight = false,
  file,
  placeholder,
  selectedAgent,
  submitQueryHandler,
  guideText,
  isAgent = false,
  setFile,
  isActive,
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [network, setNetwork] = useState<string>("");
  const [text, setText] = useState(query);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const agentState: any = useSelector((state: RootState) => state.agent);

  useEffect(() => {
    textareaRef.current?.focus();
  }, [agentState.agentResponse]);

  const isButtonDisable =
    (selectedAgent == "Chain Guardian" && network === "") ||
    query === "" ||
    loading;

  const handleUpdateNetwork = (e: any) => {
    setNetwork(e.target.value);
  };

  const handleUpdateQuery = (e: any) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (query.trim() !== "") {
      setQuery("");
      setText("");
      if (network !== "") setNetwork("");
      submitQueryHandler(network, query);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (query !== "") setQuery("");
    if (network !== "") setNetwork("");
  }, [selectedAgent]);

  useEffect(() => {
    if (textareaRef.current) {
      autoResizeTextarea();
    }
  }, [text]);

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    //@ts-ignore
    textarea.style.height = "auto";
    //@ts-ignore
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleChange = (e: any) => {
    setText(e.target.value);
    handleUpdateQuery(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      {selectedAgent == "Chain Guardian" && (
        <Box
          className={styles.select_network_box}
          sx={{
            background: (theme: any) =>
              isLight && theme.palette.mode === "dark"
                ? theme.palette.common.agentBg
                : theme.palette.common.chatInput,
            margin:
              selectedAgent == "Chain Guardian"
                ? downLg
                  ? "2rem 0 1rem"
                  : "0 0 1rem"
                : "0",
          }}
        >
          <select
            name="network"
            value={network}
            onChange={handleUpdateNetwork}
            className={styles.select_network_box}
          >
            <option value="">-- Select Network --</option>
            {networks.map((network: string) => (
              <option value={network.toLocaleLowerCase()} key={network}>
                {network}
              </option>
            ))}
            {unavailable_networks.map((network: string) => (
              <option value="" key={network} disabled style={{ opacity: ".7" }}>
                {network} (Coming Soon)
              </option>
            ))}
          </select>
        </Box>
      )}
      <Box
        className={styles.agent_input}
        sx={{
          background: (theme: any) =>
            isLight && theme.palette.mode === "dark"
              ? theme.palette.common.agentBg
              : theme.palette.common.chatInput,
          margin:
            selectedAgent === "botDetection"
              ? "0"
              : downLg
                ? isAgent
                  ? `2rem 0 0`
                  : `2rem 0`
                : "0",
        }}
      >
        {/* {selectedAgent == "Influencers Explorer" && (
          <Box
            className={styles.file_picker}
            sx={{
              background: (theme: any) =>
                theme.palette.mode === "dark"
                  ? theme.palette.grey[500]
                  : theme.palette.grey[300],
            }}
          >
            <IoIosAttach size={23} />
            <input
              type="file"
              accept=".xml,image/*"
              //@ts-ignore
              onChange={(e) => setFile(e.target.files[0])}
              value={""}
            />
          </Box>
        )} */}
        <textarea
          maxLength={4096}
          ref={textareaRef}
          value={text}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          disabled={agentState.agentResponseLoading}
          //@ts-ignore
          rows="1"
          style={{
            paddingLeft: "2rem",
            marginLeft: 0,
            width: "calc(100% - 60px)",
          }}
          className={`customScrollbar ${
            theme.palette.mode === "light"
              ? "customScrollbar-light"
              : "customScrollbar-dark"
          }`}
        />
        <Button
          type="submit"
          sx={{
            color: theme.palette.common.white,
            opacity: isButtonDisable ? 0.6 : 1,
            borderTop: downLg
              ? `1px solid ${theme.palette.common.white}`
              : "none",
            background: (theme: any) =>
              downLg
                ? theme.palette.mode === "dark"
                  ? theme.palette.grey[500]
                  : theme.palette.grey[300]
                : "transparent",
          }}
          disabled={isButtonDisable}
        >
          <IoSend
            size={downLg ? 18 : 29}
            color={
              downLg ? theme.palette.common.black : theme.palette.common.white
            }
          />
        </Button>
      </Box>
      {guideText && (
        <Typography
          variant="caption"
          align="left"
          ml={downLg ? 2 : 4}
          mt={1}
          sx={{
            fontWeight: 100,
            color: (theme: any) => theme.palette.common.mute,
          }}
        >
          {guideText}
        </Typography>
      )}
    </form>
  );
};

export default ChatInput;
