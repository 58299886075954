import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import Loading from "../../../common/loading";
import AnalyticsTitle from "../../../common/title/AnalyticsTitle";
import SpsLineChart from "../../../charts/SpsLineChart";
import { RootState } from "../../../../features/store";

const CoinSps = ({
  postsCountData,
  pricesData,
  loading,
  selectedCoin,
}: {
  postsCountData: any;
  pricesData: any;
  loading: any;
  selectedCoin: any;
}) => {
  const theme = useTheme();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  function filterData(postsCountData: any, pricesData: any) {
    const priceDates = new Set(pricesData.map((item: any) => item.x));
    const filteredPostsCountData = postsCountData.filter((post: any) =>
      priceDates.has(post.x)
    );
    return filteredPostsCountData;
  }
  const isCoinValid = cryptosState?.coinsStatistics?.find(
    (coin: any) => coin.coin_id === selectedCoin
  )?.calc_model;

  return (
    <>
      <AnalyticsTitle isLarge title="SPS History" />

      <Box sx={{ padding: "1rem" }}>
        <Typography
          variant="body2"
          align="center"
          sx={{
            margin: "1rem auto 3rem",
            fontWeight: 100,
            color: theme.palette.text.primary,
          }}
        >
          The line charts below illustrates the Social Prediction Signal (SPS)
          for this coin over the past four days, along with coin's price
          movement during the same period.
        </Typography>

        <Box
          sx={{
            margin: "0 auto 3rem",
          }}
        >
          {loading ? (
            <Loading />
          ) : !isCoinValid ? (
            <Typography
              variant="h6"
              align="center"
              mt={2}
              color={theme.palette.text.primary}
            >
              Given the number of tweets influencers have had about this coin in
              the last 24 hours, providing a signal is not available
            </Typography>
          ) : postsCountData && pricesData && pricesData.length > 0 ? (
            <SpsLineChart
              name="SPS HISTORY"
              data={[
                {
                  name: "SPS",
                  type: "line",
                  data: filterData(postsCountData, pricesData),
                },
                {
                  name: "Price",
                  type: "line",
                  data: pricesData,
                },
              ]}
            />
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default CoinSps;
