import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { TypeWriterProps } from "../interfaces";

const TypeWriter: React.FC<TypeWriterProps> = ({
  text,
  delay = 50,
  isTyping,
  responseTypingHandler,
  responseIndex,
  setCompleteResponseTyping,
}) => {
  const theme = useTheme();
  const [currentText, setCurrentText] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  useEffect(() => {
    if (isTyping) {
      if (currentIndex < text?.length) {
        const timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, delay);

        return () => {
          clearTimeout(timeout);
        };
      } else {
        if (currentIndex !== 0 && text?.length !== 0) {
          responseTypingHandler();
        }
      }
    } else {
      setCurrentIndex(text?.length);
    }
  }, [currentIndex, text, isTyping, delay, responseTypingHandler]);

  useEffect(() => {
    if (isTyping) {
      if (currentIndex === 0) {
        setCompleteResponseTyping((prev) =>
          prev.map((i, index) => (index === responseIndex ? false : i))
        );
      } else if (currentIndex === text?.length) {
        setCompleteResponseTyping((prev) =>
          prev.map((i, index) => (index === responseIndex ? true : i))
        );
      }
    } else {
      setCompleteResponseTyping((prev) =>
        prev.map((i, index) => (index === responseIndex ? true : i))
      );
    }
  }, [currentIndex, responseIndex, isTyping, text?.length]);

  const renderTextWithLineBreaks = (text: string) => {
    const boldText = text
      ?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      ?.replace(/###\s*(.*?)/g, "<h5>$1</h5>");

    return boldText
      ?.split("\n")
      ?.map((line, index) => (
        <Typography
          key={index}
          lineHeight={1.5}
          fontSize="1rem"
          fontWeight="100"
          color={theme.palette.text.primary}
          sx={{ wordWrap: "break-word" }}
          dangerouslySetInnerHTML={{ __html: line }}
        />
      ));
  };

  return (
    <Box>
      {isTyping
        ? renderTextWithLineBreaks(currentText)
        : renderTextWithLineBreaks(text)}
    </Box>
  );
};

export default TypeWriter;
