import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import {
  setAnalyticsSelectedNewsTab,
  setAnalyticsSelectedTopNewsTab,
} from "../../../features/crypto/cryptoSlice";
import { fetchInfluencerSignal } from "../../../features/signal/signalSlice";
import { CardHeaderButtonProps } from "../interfaces";
// import AlertModal from "../alertModals";
import styles from "./buttons.module.scss";
import PriceAlert from "../alertModals/priceAlert";
import EngagementAlert from "../alertModals/engagementAlert";
import DefaultModal from "../modal";
import { useNavigate } from "react-router-dom";
import LoginAlertModal from "../loginAlertModal";

const CardHeaderButton: React.FC<CardHeaderButtonProps> = ({
  tabTitle,
  title,
  name,
  isActive,
  type,
}) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const cryptoState: any = useSelector((state: RootState) => state.crypto);
  const accountState: any = useSelector((state: RootState) => state.account);

  const navigate = useNavigate();

  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);
  const [openPriceAlertModal, setOpenPriceAlertModal] = useState(false);
  const handleOpenPriceModal = () => setOpenPriceAlertModal(true);
  const handleClosePriceModal = () => setOpenPriceAlertModal(false);

  const [openEngagementAlertModal, setOpenEngagementAlertModal] =
    useState(false);
  const handleOpenEngagementModal = () => setOpenEngagementAlertModal(true);
  const handleCloseEngagementModal = () => setOpenEngagementAlertModal(false);

  const selectedTabChangeHandler = (selectedTabName: string) => {
    if (accountState.isAuthenticated) {
      const coinSignalRequest = {
        coin: cryptoState.analyticsSelectedCoin,
        page: 1,
        category: selectedTabName,
      };

      if (tabTitle === "News") {
        dispatch(setAnalyticsSelectedNewsTab(selectedTabName));
        dispatch(fetchInfluencerSignal(coinSignalRequest));
      } else if (tabTitle === "Top News") {
        dispatch(setAnalyticsSelectedTopNewsTab(selectedTabName));
      } else if (selectedTabName === "Set Alert" && type == "priceAlert") {
        setOpenPriceAlertModal(true);
      } else if (type == "engagementAlert") {
        setOpenEngagementAlertModal(true);
      }
    } else {
      setLoginAlertModal(true);
    }
  };

  return (
    <>
      <Button
        size="small"
        className={styles.card_header_button}
        sx={{
          color: (theme: any) => theme.palette.common.darkPrimary,
          background: isActive
            ? theme.palette.primary.main
            : theme.palette.common.white,

          "&:hover": {
            color: (theme: any) => theme.palette.common.darkPrimary,
            background: theme.palette.secondary.light,
          },
        }}
        onClick={() => selectedTabChangeHandler(name ?? "")}
      >
        {title}
      </Button>
      {openLoginAlertModal && (
        <LoginAlertModal
          open={openLoginAlertModal}
          setOpen={setLoginAlertModal}
        />
      )}
      {type == "priceAlert" && openPriceAlertModal && (
        <PriceAlert handleClose={handleClosePriceModal} />
      )}
      {type == "engagementAlert" && openEngagementAlertModal && (
        <EngagementAlert handleClose={handleCloseEngagementModal} />
      )}
      {/* {openAlertModal && <AlertModal handleClose={handleClose} />} */}
    </>
  );
};

export default CardHeaderButton;
