import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Logo from "../../../components/common/logo";
import Input from "../../../components/form/Input";
import AuthTitle from "../../../components/common/title/AuthTitle";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import verificationIcon from "../../../assets/images/icons/verification-icon.png";
import AuthImageDark from "../../../assets/images/auth-image-dark.png";
import AuthImageLight from "../../../assets/images/auth-image-light.png";
import CoinfidentImage from "../../../assets/images/icons/coinfident.png";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import styles from "../auth.module.scss";
import { changePasswordState } from "../interfaces";
import http from "../../../services/http";

const { Helmet } = require("react-helmet");

export const ChangePassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const hasToken = localStorage.getItem("access-token");
  const validationScheme = useCreateSchema(["password1", "password2"]);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<changePasswordState>({
    password1: "",
    password2: "",
  });
  const [changePassword, setChangePassword] = useState(false);

  const passwordUpdateHandler =
    () => async (values: changePasswordState, formik: any) => {
      setLoading(true);

      const response = await http
        .post("/auth/password/change/", {
          new_password1: values.password1,
          new_password2: values.password2,
        })
        .then((response) => {
          if (response) {
            setLoading(false);
            if (response.status === 200) {
              setInitialValues({ ...values }); // به‌روزرسانی initialValues با مقادیر جدید
              toast.success("Password Change");
              setChangePassword(true);
              setLoading(false);
              navigate("/login");
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.new_password2) {
            const errorMessages = error.response.data.new_password2;

            if (errorMessages[0]) {
              toast.error(errorMessages[0]);
            }

            if (errorMessages[1]) {
              toast.error(errorMessages[1]);
            }
          }

          setLoading(false);
          formik.resetForm();
        });
    };

  useEffect(() => {
    if (hasToken) {
      navigate("/analytics");
    }
  }, []);
  return (
    <>
      <Box className={styles.loginWrapper}>
        <Helmet>
          <title>Coinfident | Change password</title>
        </Helmet>
        <Logo isAuth />
        {changePassword ? (
          <Box className={styles.verificationMessage}>
            <Fade>
              <img src={verificationIcon} alt="verification-icon" />
              <Typography
                variant="body1"
                align="center"
                sx={{ color: theme.palette.text.primary }}
              >
                Password sent to your email. <br /> Please check your inbox.
              </Typography>
            </Fade>
          </Box>
        ) : (
          <Grid
            container
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item md={6} xs={12}>
              <Fade>
                <AuthTitle margin="80px 0 120px">Reset password</AuthTitle>
                <Formik
                  initialValues={initialValues}
                  validateOnMount={true}
                  validationSchema={validationScheme}
                  enableReinitialize={true}
                  onSubmit={passwordUpdateHandler()}
                >
                  {(formik) => (
                    <Form id="reset_password_form">
                      <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} sm={7}>
                          <Input
                            name="password1"
                            formik={formik}
                            label="Password"
                            hasInlineLabel={true}
                            hasInnerLabel={false}
                          />
                          <div style={{ marginTop: "40px" }}>
                            <Input
                              name="password2"
                              formik={formik}
                              label="Confirm Password"
                              hasInlineLabel={true}
                              hasInnerLabel={false}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <PrimaryButton
                            text="Change password"
                            disabled={!formik.isValid}
                            loading={loading}
                            isFullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Box className={styles.linkWrapper}>
                            <Typography
                              variant="body2"
                              align="left"
                              sx={{
                                color: theme.palette.text.primary,
                              }}
                            >
                              Already have the password?
                            </Typography>
                            <Link
                              to="/login"
                              style={{ color: theme.palette.primary.main }}
                            >
                              Login
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Fade>
            </Grid>

            {!downLg && (
              <Grid item md={6} xs={12} sx={{ height: "100%" }}>
                <Fade>
                  <Box
                    className={styles.authImage}
                    sx={{
                      boxShadow:
                        theme.palette.mode === "dark"
                          ? `inset 10px 3px 45px rgba(255, 255, 255, 0.3)`
                          : `inset 10px 3px 45px rgba(0, 0, 0, 0.3)`,
                      background: `url(${
                        theme.palette.mode === "dark"
                          ? AuthImageDark
                          : AuthImageLight
                      })`,
                    }}
                  >
                    {/* <img src={CoinfidentImage} className={styles.auth_icon} /> */}
                  </Box>
                </Fade>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </>
  );
};
