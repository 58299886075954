import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00AEEF", // change
      light: "#edfbff",
    },
    secondary: {
      main: "#B9E5FA", // change
    },
    text: {
      primary: "#033127",
      secondary: "#31574C",
      disabled: "linear-gradient(180deg, #636563 0%, #2e3030 100%)",
    },
    background: {
      default: "#fff",
      paper: "linear-gradient(180deg, #00AEEFCF 0%, #B9E5FA80 100%)",
    },
    error: {
      main: "#fa637a",
    },
    warning: {
      main: "#75D6A1",
    },
    common: {
      card: "linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(244, 244, 244, 0.29) 0.01%, rgba(4, 170, 142, 0.30) 100%)",
      solidCard: "linear-gradient(180deg, #00AEEF 0%, #B9E5FA 100%)",
      green: "#00AEEF", // change
      backdrop: "rgba(255, 255, 255, 0.9)",
      popup: "linear-gradient(180deg, #eee 0%, #fff 100%)",
      mute: "#555",
      footer: "rgba(255, 255, 255, 0.9)",
      whiteToGreen: "#00AEEF",
      greenText: "#004f16",
      whiteToDarkGreen: "#1D584D",
      tableSolidColor: "rgb(219,251,255)",
      primaryToGreen: "#004f16",
      greenToWhite: "#fff",
      icon: "#00AEEF",
      selectedFooterItem: "rgba(179, 201, 195, 0.42)",
      darkPrimary: "#00AEEF",
      agentModalBg: "#f6fffd",
      agentBg: "#B9E5FA",
      chatInput: "#00AEEF",
      black: "#000000B2",
    },
    grey: {
      main: "rgba(217, 215, 215, 1)",
      light: "rgba(0, 0, 0, 0.2)",
      dark: "rgba(0,0,0,0.7)",
      700: "rgba(0, 0, 0, 0.2)",
      400: "#D9D7D7",
    },
  },
  typography: {
    allVariants: {
      color: "#fff",
    },
    fontFamily: "Poppins",
  },
});
