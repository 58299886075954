import Analytics from "../pages/analytics";
import AssetsManagement from "../pages/assetsManagement";
import Login from "../pages/auth/login";
import ResetPassword from "../pages/auth/resetPassword";
import SignUp from "../pages/auth/signUp";
import Influencer from "../pages/influencer";
import Influencers from "../pages/influencers";
import ShareOfVoice from "../pages/shareOfVoice";
import News from "../pages/news";
import NotFound from "../pages/notFound";
import CryptoPage from "../pages/cryptoPage";
import Prices from "../pages/prices";
import Account from "../pages/account";
import Profile from "../pages/profile";
import AccountManagement from "../pages/accountManagement";
import FAQ from "../pages/faq";
import TermsAndConditions from "../pages/termsAndConditions";
import AddCrypto from "../pages/assetsManagement/addCrypto";
import AddCash from "../pages/assetsManagement/addCash";
import ConnectWallet from "../pages/assetsManagement/connectWallet";
import ConnectedWallets from "../pages/assetsManagement/connectedWallets";
import Alerts from "../pages/alerts";
import Coins from "../pages/coins";
import Features from "../pages/features";
import Subscription from "../pages/subscription";
import Security from "../pages/security";
import CoinfidentSignalsWidget from "../pages/coinfidentWidgets";
import InfluencersVisual from "../pages/influencersVisual";
import FaqPage from "../pages/faqPage";
import Agents from "../pages/agents";
import BackTest from "../pages/BackTest";
import { ChangePassword } from "../pages/auth/ChangePassword";

export const mainRoutes = [
  { pathName: "/", element: <Analytics /> },
  { pathName: "/login", element: <Login /> },
  { pathName: "/signup", element: <SignUp /> },
  { pathName: "/reset-password", element: <ResetPassword /> },

  { pathName: "/account", element: <Account /> },
  { pathName: "/account/profile", element: <Profile /> },
  { pathName: "/account/management", element: <AccountManagement /> },
  { pathName: "/account/FAQ", element: <FAQ /> },
  {
    pathName: "/account/terms-and-conditions",
    element: <TermsAndConditions />,
  },

  { pathName: "/analytics", element: <Analytics /> },

  { pathName: "/subscription", element: <Subscription /> },
  { pathName: "/news", element: <News /> },
  { pathName: "/influencers", element: <Influencers /> },
  { pathName: "/influencers-visual", element: <InfluencersVisual /> },
  { pathName: "/FAQ", element: <FaqPage /> },

  { pathName: "/influencers/:username", element: <Influencer /> },
  { pathName: "/prices/:crypto", element: <CryptoPage /> },
  { pathName: "/security", element: <Security /> },

  { pathName: "/prices", element: <Prices /> },
  { pathName: "/coins", element: <Coins /> },
  { pathName: "/social-media-activity", element: <ShareOfVoice /> },
  { pathName: "/alerts", element: <Alerts /> },
  { pathName: "/features", element: <Features /> },

  { pathName: "/agents", element: <Agents /> },
  { pathName: "/agents/:id", element: <Agents /> },

  { pathName: "/asset-tracking", element: <AssetsManagement /> },
  { pathName: "/asset-tracking/add-crypto", element: <AddCrypto /> },
  { pathName: "/asset-tracking/add-cash", element: <AddCash /> },
  { pathName: "/asset-tracking/connect-wallet", element: <ConnectWallet /> },
  {
    pathName: "/asset-tracking/connected-wallets",
    element: <ConnectedWallets />,
  },
  {
    pathName: "/coinfident-signals-widget",
    element: <CoinfidentSignalsWidget />,
  },

  { pathName: "*", element: <NotFound /> },
  { pathName: "/BackTest", element: <BackTest /> },
  { pathName: "/Change-Password", element: <ChangePassword /> },
];
