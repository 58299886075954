import {
  Autocomplete,
  Box,
  createFilterOptions,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { InfluencersSearchProps } from "../interfaces";
import { FaSearch } from "react-icons/fa";
import { useEffect, useState } from "react";
import { influencers } from "../../../data/influencers";
import { useNavigate } from "react-router-dom";
import Avatar from "../../common/avatar";

interface InfluencerSearchType {
  screen_name: string;
  name: string;
  id_str: number;
}

const InfluencersSearch: React.FC<InfluencersSearchProps> = () => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [value, setValue] = useState<InfluencerSearchType | null>(null);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (value) {
      navigate(`/influencers/${value.screen_name}`, {
        state: { id: value.id_str },
      });
    }
  }, [value]);

  // set logic for search options
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: InfluencerSearchType) =>
      option.screen_name + option.name,
  });

  const paperComponent = (children: React.ReactNode) => (
    <Paper
      sx={{
        backgroundColor:
          theme.palette.mode === "dark"
            ? (theme: any) => theme.palette.common.darkPrimary
            : theme.palette.primary.light,
      }}
    >
      {children}
    </Paper>
  );

  const renderOption = (
    props: any,
    option: InfluencerSearchType
  ): React.ReactElement => (
    <Box {...props}>
      <Box display={"flex"} alignItems={"center"}>
        <Avatar screenName={option.screen_name} width={35} height={35} />
        <Box sx={{ marginLeft: "8px" }}>
          <Typography
            variant="body1"
            component="h1"
            sx={{ color: theme.palette.text.primary }}
          >
            {option.name}
          </Typography>
          <Typography
            variant="caption"
            component="h2"
            sx={{ color: theme.palette.text.primary }}
          >
            @{option.screen_name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Autocomplete
      freeSolo
      disablePortal
      disableClearable
      getOptionLabel={(option: any) => option.screen_name}
      isOptionEqualToValue={(option, value) => option === value}
      value={value?.screen_name}
      onChange={(event, newValue: any) => setValue(newValue)}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      options={influencers}
      fullWidth
      size="small"
      PaperComponent={({ children }) => paperComponent(children)}
      open={!!inputValue}
      renderOption={(props, option) => renderOption(props, option)}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={downSm ? "Search" : "Search Influencers"}
          InputLabelProps={{
            sx: {
              color: theme.palette.text.primary,
              fontSize: downSm ? "0.7rem" : "1rem",
              lineHeight: downSm ? "1.8em" : "1.4375",
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ mr: 1, color: theme.palette.text.primary }}
              >
                <FaSearch size={20} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default InfluencersSearch;
