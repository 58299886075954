import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { convertCoins } from "../../utils/functions";
import { CryptosResponseState } from "./interfaces";
import data from "../../components/profile/data";

export const fetchAvailableCoins = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("available coins", async (_, thunkAPI) => {
  try {
    const url = "/crypto/get-coin-list/";
    const response = await http.get(url);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCoinsStatistics = createAsyncThunk<
  any,
  number,
  { rejectValue: any }
>("coins statistics", async (pageNumber, thunkAPI) => {
  try {
    const url = `/crypto/get-coins-statistics/?page_size=100`;
    const response = await http.get(url);
    //@ts-ignore
    const data = convertCoins(Object.values(response.data.response.data.coins));
    // return data
    return { data, overall: response.data.response.data["overall signal"] };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCoinsMarketcapWeighted = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("coins marketcap weighted", async (_, thunkAPI) => {
  try {
    const url = `/crypto/get-innovative-parameter/`;
    const response = await http.get(url);
    //@ts-ignore
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchPinnedCoins = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("pinned coins", async (_, thunkAPI) => {
  try {
    const url = `/crypto/pinned-coin-list/`;
    const response = await http.get(url);
    //@ts-ignore
    const data = response.data.response.data.results;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
// export const fetchSpsHistory = createAsyncThunk<
//     any,
//     number,
//     { rejectValue: any }
// >("sps histoory", async (coinId, thunkAPI) => {
//   const endTime = new Date();
//
// // Calculate the date 30 days ago
//   const daysToSubtract = 30;
//   const pastDate = new Date(endTime.getTime() - (daysToSubtract * 24 * 60 * 60 * 1000));
//
// // Extract the year, month, and day for both dates
//   const yearNow = endTime.getFullYear();
//   let monthNow = (endTime.getMonth() + 1).toString().padStart(2, '0');
//   let dayNow = endTime.getDate().toString().padStart(2, '0');
//
// // Format current date
//   const formattedCurrentDate = `${yearNow}-${monthNow}-${dayNow}`;
//
// // Extract year, month, and day for the past date
//   const yearPast = pastDate.getFullYear();
//   let monthPast = (pastDate.getMonth() + 1).toString().padStart(2, '0');
//   let dayPast = pastDate.getDate().toString().padStart(2, '0');
//
// // Format 30 days ago date
//   const startTime = `${yearPast}-${monthPast}-${dayPast}`;
//
//   console.log(startTime)
//   console.log(formattedCurrentDate)
//   try {
//     const url = `/crypto/get-sps-price-coin/${startTime}/${formattedCurrentDate}/${coinId}/`;
//     const response = await http.get(url);
//     //@ts-ignore
//     const data = response.data.response.data;
//     console.log(data)
//     return data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error);
//   }
// });

export const fetchSPSChanges = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("sps changes", async (_, thunkAPI) => {
  try {
    const url = `/crypto/get-sps-changes/`;
    const response = await http.get(url);
    const data = response.data?.coins_sps;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: CryptosResponseState = {
  coinStatisticsState: {
    page: 1,
    result: [],
  },
  overallSignal: null,
  coinsStatistics: null,
  availableCoins: null,
  marketcapWeighted: null,
  pinnedCoins: null,
  SPSHistory: null,
  spsChanges: null,
  marketcapWeightedLoading: true,
  coinsStatisticsLoading: true,
  availableCoinsLoading: false,
  pinnedCoinsLoading: true,
  SPSHistoryLoading: true,
  spsChangesLoading: true,
  error: null,
};

export const cryptosSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    setCoinStatistics: (state, action) => {
      state.coinStatisticsState = {
        page: action.payload.page,
        result: action.payload.result,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableCoins.pending, (state) => {
        state.availableCoinsLoading = true;
        state.error = null;
      })
      .addCase(fetchAvailableCoins.fulfilled, (state, action) => {
        state.availableCoinsLoading = false;
        state.availableCoins = action.payload;
      })
      .addCase(fetchAvailableCoins.rejected, (state, action) => {
        state.availableCoinsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchCoinsStatistics.pending, (state) => {
        state.coinsStatisticsLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinsStatistics.fulfilled, (state, action) => {
        state.coinsStatisticsLoading = false;
        state.coinsStatistics = action.payload.data;
        state.overallSignal = action.payload.overall;
      })
      .addCase(fetchCoinsStatistics.rejected, (state, action) => {
        state.coinsStatisticsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchCoinsMarketcapWeighted.pending, (state) => {
        state.marketcapWeightedLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinsMarketcapWeighted.fulfilled, (state, action) => {
        state.marketcapWeightedLoading = false;
        state.marketcapWeighted = action.payload;
      })
      .addCase(fetchCoinsMarketcapWeighted.rejected, (state, action) => {
        state.marketcapWeightedLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchPinnedCoins.pending, (state) => {
        state.pinnedCoinsLoading = true;
        state.error = null;
      })
      .addCase(fetchPinnedCoins.fulfilled, (state, action) => {
        state.pinnedCoinsLoading = false;
        state.pinnedCoins = action.payload;
      })
      .addCase(fetchPinnedCoins.rejected, (state, action) => {
        state.pinnedCoinsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchSPSChanges.pending, (state) => {
        state.spsChangesLoading = true;
      })
      .addCase(fetchSPSChanges.fulfilled, (state, action) => {
        state.spsChanges = action.payload;
        state.spsChangesLoading = false;
      })
      .addCase(fetchSPSChanges.rejected, (state) => {
        state.spsChangesLoading = true;
      });
  },
});
export default cryptosSlice.reducer;

export const { setCoinStatistics } = cryptosSlice.actions;
