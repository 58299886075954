import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import toast from "react-hot-toast";
import { TbChartCandle, TbChartLine } from "react-icons/tb";
import { CryptoPageType } from "../interfaces";
import { convertData } from "../../../utils/functions";
import { RootState } from "../../../features/store";
import Loading from "../../../components/common/loading";
import FinancialChart from "../../../components/crypto/financialChart";
import CryptoHeader from "../../../components/cryptoPage/header";
import CryptoStatistics from "../../../components/cryptoPage/statistics";
import CryptoSignal from "../../../components/cryptoPage/signal-sentiment";
import CryptoTabsSelector from "../../../components/cryptoPage/tabsSelector";
import CryptoChart from "../../../components/cryptoPage/chart";
import CryptoTechnicalSignals from "../../../components/cryptoPage/technicalSignals";
import CryptoRssNews from "../../../components/cryptoPage/rssNews";
import CryptoInfluencers from "../../../components/cryptoPage/influencers";
import CryptoExchanges from "../../../components/cryptoPage/exchanges";
import Performance from "../../../components/crypto/performance";
import SocialMediaActivityChart from "../../../components/charts/SocialMediaActivityChart";
import styles from "../cryptoPage.module.scss";

const DesktopView = ({
  cryptosState,
  cryptoState,
  assetAvailability,
  cryptoChartData,
  cryptoFilter,
  setCryptoFilter,
  cryptoIndicatorsState,
  technichalMethod,
  setTechnichalMethod,
  showTechnichalMethod,
  setShowTechnichalMethod,
  loadingHistoricalChart,
  setRssPage,
  influencers,
  newsList,
  cryptoNewsState,
  technical_signals,
}: {
  cryptosState: any;
  cryptoState: any;
  assetAvailability: any;
  cryptoChartData: any;
  cryptoFilter: any;
  setCryptoFilter: any;
  cryptoIndicatorsState: any;
  technichalMethod: any;
  setTechnichalMethod: any;
  showTechnichalMethod: any;
  setShowTechnichalMethod: any;
  loadingHistoricalChart: any;
  setRssPage: any;
  influencers: any;
  newsList: any;
  cryptoNewsState: any;
  technical_signals: any;
}) => {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const account: any = useSelector((state: RootState) => state.account);

  const [chartType, setChartType] = useState("financial");

  const handleAlignment = (newAlignment: string) => {
    setChartType(newAlignment);
  };

  const technichalMethodHandler = (method: string) => {
    if (cryptoFilter.type !== "1H" && cryptoFilter.type !== "1D") {
      setTechnichalMethod(method);
    } else {
      toast.error("This indicator is not available for this time frame");
    }

    if (technichalMethod === method) {
      setShowTechnichalMethod(!showTechnichalMethod);
    } else {
      setShowTechnichalMethod(true);
    }
  };

  const cryptoFilterChangeHandler = (type: string, interval: string) => {
    setCryptoFilter({
      type: type,
      interval: interval,
    });
  };

  const SectionTitle = (title: string, info?: string) => {
    return (
      <>
        <Typography
          component="h2"
          align="left"
          color={theme.palette.text.primary}
          sx={{
            fontSize: "1.2rem",
            fontWeight: 100,
          }}
        >
          {title}
        </Typography>
        {info && (
          <Typography
            variant="subtitle2"
            component="p"
            mb={1}
            pb={1}
            color={theme.palette.grey[500]}
            sx={{
              borderBottom: (theme: any) =>
                `1px solid ${theme.palette.common.darkPrimary}`,
            }}
          >
            {info}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={4} sx={{ paddingRight: downLg ? 0 : "2rem" }}>
        <Box
          p={2}
          sx={{
            background:
              theme.palette.mode === "dark"
                ? theme.palette.background.paper
                : theme.palette.grey[100],
          }}
          className={styles.crypto_sidebar}
        >
          {cryptosState.availableCoins ? (
            <>
              <>
                <CryptoHeader
                  cryptosState={cryptosState}
                  data={cryptoState.coin}
                  coinId={
                    location.state
                      ? location.state.coin_id
                      : cryptosState.availableCoins?.find(
                            (item: CryptoPageType) =>
                              item.name.toLowerCase() === params.crypto
                          )
                        ? cryptosState.availableCoins?.find(
                            (item: CryptoPageType) =>
                              item.name.toLowerCase() === params.crypto
                          )?.id
                        : 3
                  }
                />
                <Box mb={6}>
                  <CryptoStatistics data={cryptoState.coin} />
                </Box>
              </>

              <CryptoSignal
                isAvailable={cryptosState.availableCoins?.find(
                  (item: CryptoPageType) =>
                    item.name.toLowerCase() === params.crypto
                )}
                coinId={
                  location.state
                    ? location.state.coin_id
                    : cryptosState.availableCoins?.find(
                          (item: CryptoPageType) =>
                            item.name.toLowerCase() === params.crypto
                        )
                      ? cryptosState.availableCoins?.find(
                          (item: CryptoPageType) =>
                            item.name.toLowerCase() === params.crypto
                        )?.id
                      : 3
                }
              />
            </>
          ) : (
            <Loading />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <CryptoTabsSelector />
        {cryptoState.coin ? (
          <Box mt={4}>
            <Box>
              <ToggleButtonGroup
                value={chartType}
                exclusive
                aria-label="chart type"
                size="small"
                sx={{
                  background:
                    //@ts-ignore
                    theme.palette.grey.main,
                  borderRadius: "10px 10px 0 0",
                  minWidth: "auto !important",
                  overflow: "hidden",
                }}
              >
                <ToggleButton
                  value="financial"
                  aria-label="financial"
                  onClick={() => handleAlignment("financial")}
                  sx={{
                    padding: "7px 15px",
                    borderRadius: 0,
                  }}
                >
                  <TbChartCandle size={22} />
                </ToggleButton>
                <ToggleButton
                  value="crypto"
                  aria-label="crypto"
                  onClick={() => handleAlignment("crypto")}
                  sx={{
                    padding: "7px 15px",
                    borderRadius: 0,
                  }}
                >
                  <TbChartLine size={22} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box
              p={1}
              sx={{
                background: theme.palette.background.paper,
                borderRadius: "0 8px 8px 8px",
              }}
            >
              {chartType === "crypto" && assetAvailability ? (
                <CryptoChart
                  name="Price"
                  data={cryptoChartData}
                  cryptoFilter={cryptoFilter}
                  cryptoIndicators={cryptoIndicatorsState}
                  technichalMethod={technichalMethod}
                  showTechnichalMethod={showTechnichalMethod}
                  technichalMethodHandler={technichalMethodHandler}
                  cryptoFilterChangeHandler={cryptoFilterChangeHandler}
                  loadingHistoricalChart={loadingHistoricalChart}
                  isAvailable={cryptosState.availableCoins.find(
                    (item: CryptoPageType) => item.id === params.crypto
                  )}
                />
              ) : (
                <FinancialChart symbol={cryptoState.coin.data.symbol} />
              )}
            </Box>
          </Box>
        ) : (
          <Loading />
        )}

        <Box pt={5} id="Performance">
          {SectionTitle("Performance")}
          <Performance data={cryptoState} size="large" />
        </Box>

        {cryptoNewsState.cryptoNewsCountLoading ? (
          <Loading />
        ) : (
          cryptoNewsState.cryptoNewsCount && (
            <Box mt={5}>
              {SectionTitle("Social Activity")}
              <SocialMediaActivityChart
                name="News count"
                data={cryptoNewsState.cryptoNewsCount}
              />
            </Box>
          )
        )}

        <Box pt={5} id="Technical Signals">
          {SectionTitle("Technical Signals")}

          {!technical_signals ? (
            <Box
              className={`default-card-style `}
              sx={{ background: theme.palette.background.paper, width: "100%" }}
            >
              <Typography align="center" py={3}>
                <strong>N/A</strong> (Coming Soon)
              </Typography>
            </Box>
          ) : (
            <CryptoTechnicalSignals data={convertData(technical_signals)} />
          )}
        </Box>
        <Box pt={5} id="News Websites">
          {SectionTitle(
            "News Websites",
            "Websites that publish cryptocurrency news and offer articles specifically about the coin are all accessible in one place"
          )}

          <CryptoRssNews
            setRssPage={setRssPage}
            //@ts-ignore
            newsList={newsList}
            isAuthenticated={account.isAuthenticated}
          />
        </Box>

        {influencers?.length > 0 && (
          <Box pt={5} id="Influencers">
            {SectionTitle(
              "Influencers",
              "Profiles of influencers whose tweets about this specific cryptocurrency garnered the highest engagement"
            )}
            <CryptoInfluencers influencers={influencers} />
          </Box>
        )}

        <Box pt={5} id="Exchanges">
          {SectionTitle(
            "Exchanges",
            "A comprehensive list of all markets where this particular cryptocurrency is actively traded"
          )}
          <CryptoExchanges data={cryptoState} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DesktopView;
