import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import { Form, Formik } from "formik";
import { Fade } from "react-awesome-reveal";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { MdOutlineChevronLeft } from "react-icons/md";
import DashboardLayout from "../../../layout/dashboardLayout";
import Stars from "../../../components/common/svg/stars";
import ContentContainer from "../../../components/common/contentContainer";
import AssetDrawer from "../../../components/assetsManagement/assetDrawer";
import Loading from "../../../components/common/loading";
import CryptoList from "../../../components/crypto/cryptoList";
import Input from "../../../components/form/Input";
import ethIcon from "./../../../assets/images/icons/eth_icon.svg";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { closeAssetsDrawer } from "../../../features/assets/assetsSlice";
import { fetchAvailableCoins } from "../../../features/crypto/cryptosSlice";
import { setAnalyticsSelectedCoin } from "../../../features/crypto/cryptoSlice";
import { CryptoStateType } from "../interfaces";
import styles from "../assetsManagement.module.scss";

const AddCrypto = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const assetsState: any = useSelector((state: RootState) => state.assets);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const open: any = useSelector((state: RootState) => state.assets.openDrawer);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  const [selectedCoin, setSelectedCoin] = useState(3);
  const [initialValues, setInitialValues] = useState<CryptoStateType>({
    coin: 3,
    coinValue: 0,
  });

  const coinsSelectorHandler = (selectedCoinItem: number) => {
    setSelectedCoin(selectedCoinItem);
  };

  const submitDataHandler = (currentAssets: any) => {
    // toast.success("Successfully added to your assets");
  };

  const addCryptoHandler = (currentAssets: any, values: any) => {
    const symbol = cryptosState.availableCoins?.find(
      (crypto: any) => crypto.id === selectedCoin
    ).name;

    const existingCryptoIndex = currentAssets.findIndex(
      (asset: any) => asset.symbol === symbol
    );

    if (existingCryptoIndex !== -1) {
      const existingCrypto = { ...currentAssets[existingCryptoIndex] };
      existingCrypto.value += values.coinValue;
      currentAssets[existingCryptoIndex] = existingCrypto;
    } else {
      currentAssets.push({
        symbol: symbol,
        value: values.coinValue,
        created_date: moment().format("YYYY/MM/DD"),
      });
    }

    submitDataHandler(currentAssets);
  };

  const submitAssetsHandler = () => (values: any, formik: any) => {
    let currentAssets = [...assetsState.manualCryptoList];
    addCryptoHandler(currentAssets, values);
  };

  useEffect(() => {
    if (cryptosState.availableCoins) {
      if (!cryptosState.availableCoins) {
        dispatch(fetchAvailableCoins());
      }
    }
  }, [cryptosState.availableCoins]);

  return (
    <DashboardLayout title="Coinfident | Asset Tracking">
      <Container
        maxWidth="lg"
        onClick={() => open && dispatch(closeAssetsDrawer())}
      >
        <Box className={styles.assets_management}>
          <Fade triggerOnce>
            <img src={ethIcon} alt="icon" className={styles.eth_logo} />
          </Fade>
          <Fade className={styles.stars}>
            <Stars width={320} height={320} />
          </Fade>
          <AssetDrawer />
          <ContentContainer open={open} type="assets">
            <Typography
              mt={3}
              mb={2}
              variant="h1"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              <IconButton
                onClick={() => {
                  dispatch(setAnalyticsSelectedCoin(3));
                  navigate("/asset-tracking");
                }}
                sx={{
                  marginLeft: "-2rem",
                  marginRight: ".5rem",
                }}
              >
                <MdOutlineChevronLeft size={30} />
              </IconButton>
              Crypto Coins
            </Typography>
            <Grid
              container
              sx={{ margin: "auto", display: "flex", justifyContent: "center" }}
            >
              <Fade triggerOnce>
                <Box
                  mt={2}
                  sx={{
                    background: (theme: any) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.common.darkPrimary
                        : theme.palette.primary.light,
                    minWidth: 350,
                  }}
                  className="default-card-style"
                >
                  {cryptosState.availableCoinsLoading ? (
                    <Loading />
                  ) : (
                    <Formik
                      initialValues={initialValues}
                      validateOnMount={true}
                      enableReinitialize={true}
                      onSubmit={submitAssetsHandler()}
                    >
                      {(formik) => (
                        <Form id="manual asset form">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 100,
                                color: theme.palette.text.primary,
                              }}
                            >
                              Select Coin:
                            </Typography>
                            <CryptoList
                              coinsSelectorHandler={coinsSelectorHandler}
                              type="asset"
                            />
                          </Box>
                          <br />
                          <Input
                            name="coinValue"
                            formik={formik}
                            label="Enter Value:"
                            type="number"
                            hasInlineLabel={true}
                            hasInnerLabel={false}
                            variant="outlined"
                            initialValues={initialValues}
                            setInitialValues={setInitialValues}
                          />
                          <Button
                            disabled
                            fullWidth
                            type="submit"
                            size="small"
                            variant="contained"
                            sx={{
                              marginTop: "1.5rem",
                              "&:hover": {
                                background: theme.palette.secondary.main,
                              },
                            }}
                          >
                            ADD
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Box>
              </Fade>
            </Grid>
          </ContentContainer>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default AddCrypto;
