import { Box, useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { TreeMapProps } from "./interfaces";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import styles from "./charts.module.scss";
import Empty from "../common/empty/Empty";

const TreeMap: React.FC<TreeMapProps> = ({ data }) => {
  const theme = useTheme();

  const getMinMaxValue = useMemo(() => {
    if (isEmpty(data)) return;

    const values = data?.map((i) => i.y);
    const negativeValues = values?.filter((value) => value < 0);
    const positiveValues = values?.filter((value) => value > 0);

    const maxNumber: number = Math.max(...values);
    const minNumber: number = Math.min(...values);

    return { min: minNumber, max: maxNumber };
  }, [data]);

  const options: ApexOptions = {
    legend: {
      show: false,
    },
    chart: {
      height: 350,
      type: "treemap",
      toolbar: {
        show: false,
      },
      offsetX: 5,
    },
    dataLabels: {
      enabled: true,
      offsetY: -10,
      // @ts-ignore
      formatter: function (text, op) {
        return [
          text,
          `Tweet Count: ${op.value}`,
          `Price Change: ${
            op.w.config.series[op.seriesIndex].data[op.dataPointIndex]
              ?.priceChange
          }%`,
        ];
      },
      style: {
        fontSize: "10px",
        colors: [theme.palette.grey[900]],
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `
        <div class='${styles.linechart_tooltip}' style="background: ${
          //@ts-ignore
          theme.palette.common.solidCard
        }">
          <h6 style="color: ${theme.palette.common.white}">
            ${w.globals.categoryLabels[dataPointIndex]}
          </h6>
          <div>
            <h4 style="color: ${theme.palette.common.white}">Tweet Count:</h4>
            <h5 style="color: ${theme.palette.common.white}">
            ${series[seriesIndex][dataPointIndex]}
            </h5>
          </div>
          <div>
            <h4 style="color: ${theme.palette.common.white}">price Chagne:</h4>
            <h5 style="color: ${theme.palette.common.white} ">
            ${w.config.series[seriesIndex].data[dataPointIndex].priceChange}%
            </h5>
          </div>
        </div>`;
      },
    },
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.3,
        reverseNegativeShade: true,
        borderRadius: 0,

        colorScale: {
          min: getMinMaxValue?.min,
          max: getMinMaxValue?.max,
          ranges: [],
        },
      },
    },
  };

  return (
    <>
      {!isEmpty(data) ? (
        <Box id="treemap-chart" whiteSpace={"pre"}>
          <ReactApexChart
            key={theme.palette.mode}
            options={options}
            series={[{ data }]}
            type="treemap"
            height={350}
          />
        </Box>
      ) : (
        <Empty text="No Data" />
      )}
    </>
  );
};

export default TreeMap;
