import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useTheme } from "@mui/material";
import { MixedChartProps } from "./interfaces";
import styles from "./charts.module.scss";

const SpsLineChart: React.FC<MixedChartProps> = ({ data, name }) => {
  const [state, setState] = useState<{
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    options: object;
  }>({
    series: [],
    options: {},
  });
  const theme = useTheme();

  const pricesValues = data && data[1].data.map((price: any) => price.y);
  const maxPrice = Math.max(...pricesValues);
  const minPrice = Math.min(...pricesValues);
  const sumPrice = pricesValues.reduce((acc, price, arr) => acc + price, 0);
  const averagePrice = +(sumPrice / pricesValues?.length).toFixed(3);

  const minYaxis = Math.floor(minPrice - averagePrice / 100);
  const maxYaxis = Math.floor(maxPrice + averagePrice / 100);
  useEffect(() => {
    setState({
      series: data,
      options: {
        //@ts-ignore
        colors: [theme.palette.warning.main, theme.palette.primary.main],
        chart: {
          parentHeightOffset: 0,
          // type: "bar",
          fontFamily: "Poppins",
          toolbar: { show: false },
          zoom: { enabled: false },
          animations: {
            easing: "easeout",
          },
        },
        tooltip: {
          //@ts-ignore
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class='${
              styles.linechart_tooltip
            }' style="background: ${
              //@ts-ignore
              theme.palette.common.solidCard
            }">
                <div>
                <h4 style="color: ${theme.palette.common.white} ">${
                  w.globals.seriesNames[1]
                }:</h4>
                <h5 style="color: ${theme.palette.common.white} ">$${Number(
                  w.globals.initialSeries[1].data[dataPointIndex]?.normalize
                )?.toLocaleString()}</h5>
              </div>
              <div>
                <h4 style="color: ${theme.palette.common.white} ">${
                  w.globals.seriesNames[0]
                }:</h4>
                <h5 style="color: ${theme.palette.common.white} ">${
                  series[0][dataPointIndex]
                }%</h5>
              </div>
              </div>`;
          },
        },
        grid: { show: false },
        legend: {
          show: true,
          labels: {
            colors: theme.palette.text.primary,
          },
        },
        stroke: {
          curve: "smooth",
          width: [2, 2],
        },
        dataLabels: {
          style: {
            fontSize: "12px",
            fontWeight: 100,
          },
          enabled: false,
          // enabledOnSeries: [1],
        },
        xaxis: {
          type: "datetime",
          tickAmount: 15,
          labels: {
            show: true,
            rotateAlways: false,
            style: {
              fontSize: "10px",
              colors: theme.palette.text.primary,
            },
            formatter: (value: any, index: number) => {
              if (value && typeof value === "string") {
                const date = new Date(value);
                if (!isNaN(date.getTime())) {
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, "0");
                  const day = String(date.getDate()).padStart(2, "0");
                  return `${year}-${month}-${day}`;
                }
              }
              return "";
            },
          },
          axisBorder: { show: true },
          axisTicks: { show: false },
          tooltip: {
            enabled: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: [
          {
            axisBorder: { show: true },
            min: 0,
            max: 100,
            title: {
              text: "SPS",
              style: {
                color: theme.palette.text.primary,
                fontSize: "13px",
                fontFamily: "Poppins",
                fontWeight: 500,
              },
            },
            labels: {
              show: false,
            },
          },
          {
            axisBorder: { show: true },
            opposite: true,
            tickAmount: 3,
            stepSize: averagePrice / 100,
            min: minYaxis,
            max: maxYaxis,
            title: {
              text: "Price",
              style: {
                color: theme.palette.text.primary,
                fontSize: "13px",
                fontFamily: "Poppins",
                fontWeight: 500,
              },
            },
            labels: {
              show: false,
            },
          },
        ],
      },
    });
  }, [data]);

  return (
    <Box id="spsChart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        // type="bar"
        height="240px"
        width="100%"
      />
    </Box>
  );
};

export default SpsLineChart;
