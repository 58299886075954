import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { MdOutlineChevronLeft } from "react-icons/md";
import DashboardLayout from "../../../layout/dashboardLayout";
import AssetDrawer from "../../../components/assetsManagement/assetDrawer";
import WalletCard from "../../../components/assetsManagement/connectWallet/walletCard";
import ContentContainer from "../../../components/common/contentContainer";
import Stars from "../../../components/common/svg/stars";
import Loading from "../../../components/common/loading";
import ethIcon from "./../../../assets/images/icons/eth_icon.svg";
import { closeAssetsDrawer } from "../../../features/assets/assetsSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { fetchWalletsList } from "../../../features/assetsManagement/assetsManagementSlice";
import styles from "../assetsManagement.module.scss";

const ConnectedWallets = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();

  const open: any = useSelector((state: RootState) => state.assets.openDrawer);
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement
  );

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  useEffect(() => {
    if (!assetsManagementState.wallets) dispatch(fetchWalletsList());
  }, [assetsManagementState.wallets]);

  return (
    <DashboardLayout title="Coinfident | Asset Tracking">
      <Container
        maxWidth="lg"
        onClick={() => open && dispatch(closeAssetsDrawer())}
      >
        <Box className={styles.assets_management}>
          <Fade triggerOnce>
            <img src={ethIcon} alt="icon" className={styles.eth_logo} />
          </Fade>
          <Fade className={styles.stars}>
            <Stars width={320} height={320} />
          </Fade>
          <AssetDrawer />
          <ContentContainer open={open} type="assets">
            <Typography
              mt={3}
              mb={2}
              variant="h1"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              <IconButton
                onClick={() => navigate("/asset-tracking/connect-wallet")}
                sx={{
                  marginLeft: "-2rem",
                  marginRight: ".5rem",
                }}
              >
                <MdOutlineChevronLeft size={30} />
              </IconButton>
              Connected Wallets
            </Typography>
            {assetsManagementState.walletsLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Loading />
              </Box>
            ) : assetsManagementState.wallets?.filter(
                (walletItem: any) => walletItem.category !== "Cash"
              ).length > 0 ? (
              assetsManagementState.wallets
                .filter((walletItem: any) => walletItem.category !== "Cash")
                .map((wallet: any, idx: number) => (
                  <WalletCard wallet={wallet} key={idx} />
                ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 8,
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                  color={theme.palette.text.primary}
                >
                  No wallets found
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      background: theme.palette.secondary.main,
                    },
                  }}
                  onClick={() => navigate("/asset-tracking/connect-wallet")}
                >
                  Add wallet
                </Button>
              </Box>
            )}
          </ContentContainer>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default ConnectedWallets;
