import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import {
  influencerRankTitle,
  influencerTypePriority,
} from "../../../utils/functions";
import { getUserProfile } from "../../../utils/userPhoto";
import { RootState } from "../../../features/store";
import styles from "../analytics.module.scss";

const RankedInfluencers = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const influencersState = useSelector((state: RootState) => state.influencers);

  const sortedInfluencersSchema = useMemo(() => {
    if (
      influencersState.sortedInfluencersLoading.some((i) => i === true) ||
      influencersState.topInfluencersCoinLoading
    )
      return;

    const mergeInfluencers: any = {
      ...influencersState.sortedInfluencers,
      btc_ranking: { id: 5, data: influencersState?.topInfluencersCoin },
    };

    const influencersAddPriority = Object.entries(mergeInfluencers).map(
      ([key, value]) => {
        if (typeof value === "object" && value !== null) {
          return {
            ...value,
            priority: influencerTypePriority(key),
            name: influencerRankTitle(key),
          };
        }
      }
    );

    const sortByPriority = influencersAddPriority.sort((a, b) => {
      if (a?.priority === undefined) return 1;
      if (b?.priority === undefined) return -1;
      return a.priority - b.priority;
    });

    return sortByPriority;
  }, [
    influencersState.sortedInfluencersLoading,
    influencersState.topInfluencersCoinLoading,
  ]);

  return (
    <>
      {sortedInfluencersSchema?.map((influencerType) => (
        <Grid
          key={influencerType?.name}
          item
          lg={3.91}
          xs={12}
          className={styles.rankedInfluencerWrapper}
          sx={{
            padding: downLg ? "0.9rem .4rem" : "0.5rem",
            background: (theme: any) => theme.palette.common.agentBg,
          }}
          onClick={() => navigate("/influencers")}
        >
          <Typography
            variant="h2"
            color={(theme: any) => theme.palette.common.mute}
          >
            Top Influencers by <br />
            <strong
              style={{
                color: theme.palette.text.primary,
                fontSize: downLg ? "0.85rem" : "0.95rem",
              }}
            >
              {influencerType?.name}
            </strong>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {// @ts-ignore
            influencerType?.data
              ?.slice(0, 4)
              ?.map((infItem: any) => (
                <Avatar
                  key={infItem.screen_name}
                  src={getUserProfile(infItem.screen_name)}
                  sx={{ width: 28, height: 28, mr: 0.4 }}
                />
              ))}
            <IconButton
              size="small"
              sx={{
                padding: ".2rem",
                background: (theme: any) => theme.palette.common.icon,
              }}
            >
              <MdOutlineKeyboardArrowRight size={14} />
            </IconButton>
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default RankedInfluencers;
