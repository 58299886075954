import { Link } from "react-router-dom";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GoHeart } from "react-icons/go";
import { AiOutlineRetweet } from "react-icons/ai";
import { TbMessageCircle } from "react-icons/tb";
import Avatar from "../avatar";
import NewsAvatar from "../newsAvatar";
import { NewsCardProps } from "../interfaces";
import styles from "./news.module.scss";

const NewsGallery: React.FC<NewsCardProps> = ({
  id,
  screen_name,
  name,
  date,
  content,
  media,
  reply_count,
  favorite_count,
  retweet_count,
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      className={`${styles.newsGallery}`}
      sx={{
        marginBottom: downLg ? ".5rem" : 0,
        background: theme.palette.primary.light,
        border: (theme: any) => `1px solid ${theme.palette.common.darkPrimary}`,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ flexDirection: "column", flex: 1 }}>
          <Box className={styles.newsGalleryTitle}>
            <Link to={`/influencers/${screen_name}`} state={{ id }}>
              <Avatar screenName={screen_name} width={30} height={30} />
            </Link>
            <Link to={`/influencers/${screen_name}`} state={{ id }}>
              <Typography
                variant="body2"
                component="h6"
                //@ts-ignore
                sx={{ color: theme.palette.common.whiteToGreen }}
              >
                {name}
              </Typography>
            </Link>
            <Typography
              variant="caption"
              sx={{
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey["400"]
                    : //@ts-ignore
                      theme.palette.common.green,
              }}
            >
              {date}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.newsGalleryMedia}>
          {media && (
            <NewsAvatar
              isGallery
              fullWidth
              type="tweet"
              imageUrl={media}
              width={90}
              height={90}
            />
          )}
        </Box>
        <Typography
          variant="body2"
          component="p"
          //@ts-ignore
          sx={{
            color:
              theme.palette.mode === "dark"
                ? theme.palette.grey["400"]
                : //@ts-ignore
                  theme.palette.common.whiteToDarkGreen,
          }}
        >
          {content}
        </Typography>

        {reply_count || favorite_count || retweet_count ? (
          <Box className={styles.newsGalleryInfo}>
            <Typography color={theme.palette.text.primary}>
              <TbMessageCircle />
              {reply_count?.toLocaleString()}
            </Typography>
            <Typography color={theme.palette.text.primary}>
              <GoHeart />
              {favorite_count?.toLocaleString()}
            </Typography>
            <Typography color={theme.palette.text.primary}>
              <AiOutlineRetweet />
              {retweet_count?.toLocaleString()}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default NewsGallery;
