import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { GoHeart } from "react-icons/go";
import { FiChevronDown } from "react-icons/fi";
import { AiOutlineRetweet } from "react-icons/ai";
import { TbMessageCircle } from "react-icons/tb";
import { getLinesCountOfAnElement } from "../../../utils/functions";
import Avatar from "../avatar";
import NewsAvatar from "../newsAvatar";
import { NewsCardProps } from "../interfaces";
import styles from "./news.module.scss";

const NewsCard: React.FC<NewsCardProps> = ({
  id,
  screen_name,
  name,
  date,
  content,
  media,
  reply_count,
  favorite_count,
  retweet_count,
}) => {
  const theme = useTheme();
  const newsTextElement = useRef();

  const [expanded, setExpanded] = useState(false);
  const [isCardExpandable, setIsCardExpandable] = useState(true);

  const checkIfCardIsExpandable = () => {
    const linesCount = getLinesCountOfAnElement(newsTextElement.current);

    if (linesCount <= 3) {
      setIsCardExpandable(false);
      setExpanded(true);
    }
  };

  useEffect(() => {
    checkIfCardIsExpandable();
  }, []);

  return (
    <Box className={`${styles.newsCard} newsCardWrapper`}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Accordion
          expanded={expanded}
          sx={{
            boxShadow: "none",
            background: "transparent",
            "& .MuiCollapse-root": {
              visibility: "visible",
              minHeight: "60px !important",
            },
          }}
        >
          <AccordionSummary
            sx={{ minHeight: "0 !important", height: "0 !important" }}
          />
          <AccordionDetails className={styles.newsCardWrapper}>
            <Link to={`/influencers/${screen_name}`} state={{ id }}>
              <Avatar screenName={screen_name} width={30} height={30} />
            </Link>
            <Box sx={{ flexDirection: "column", marginLeft: "7px", flex: 1 }}>
              <Box className={styles.newsCardTitle}>
                <Link to={`/influencers/${screen_name}`} state={{ id }}>
                  <Typography
                    variant="body2"
                    component="h6"
                    //@ts-ignore
                    sx={{ color: theme.palette.common.whiteToGreen }}
                  >
                    {name}
                  </Typography>
                </Link>
                <Typography
                  variant="caption"
                  sx={{
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.grey["400"]
                        : //@ts-ignore
                          theme.palette.common.green,
                  }}
                >
                  {date}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                component="p"
                //@ts-ignore
                ref={newsTextElement}
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey["400"]
                      : //@ts-ignore
                        theme.palette.common.whiteToDarkGreen,
                }}
                onClick={() => isCardExpandable && setExpanded(!expanded)}
              >
                {content}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box className={styles.newsCardMedia}>
          {media && (
            <NewsAvatar
              fullWidth
              type="tweet"
              imageUrl={media}
              width={90}
              height={90}
            />
          )}
        </Box>
        {reply_count || favorite_count || retweet_count ? (
          <Box className={styles.newsCardInfo}>
            <Typography color={theme.palette.text.primary}>
              <TbMessageCircle />
              {reply_count?.toLocaleString()}
            </Typography>
            <Typography color={theme.palette.text.primary}>
              <GoHeart />
              {favorite_count?.toLocaleString()}
            </Typography>
            <Typography color={theme.palette.text.primary}>
              <AiOutlineRetweet />
              {retweet_count?.toLocaleString()}
            </Typography>
          </Box>
        ) : null}
      </Box>

      {isCardExpandable && (
        <Box
          className={styles.newsCardExtraButton}
          onClick={() => setExpanded(!expanded)}
        >
          <FiChevronDown
            size={22}
            style={{
              transition: "270ms",
              transform: expanded ? "rotate(180deg)" : "rotate(0)",
              //@ts-ignore
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.common.white
                  : //@ts-ignore
                    theme.palette.common.green,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default NewsCard;
