import { useState } from "react";
import { useSelector } from "react-redux";
import { Slide } from "react-awesome-reveal";
import toast from "react-hot-toast";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { FaXTwitter } from "react-icons/fa6";
import { RiPushpin2Fill } from "react-icons/ri";
import { IoShareSocialOutline } from "react-icons/io5";
import { PiPushPinSimpleSlashFill } from "react-icons/pi";
import BottomDrawer from "../../common/bottomDrawer";
import Share from "../../common/share";
import { pinInfluencer, removeInfluencerPin } from "../../../api/influencers";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { fetchPinnedInfluencers } from "../../../features/influencers/influencersSlice";
import { InfluencerPopoverProps } from "../interfaces";
import LoginAlertModal from "../../common/loginAlertModal";
import styles from "../influencers.module.scss";

const InfluencerPopover: React.FC<InfluencerPopoverProps> = ({
  isVisible,
  setIsVisible,
  position,
  influencerData,
  isPinned,
  setClickedInfluencerData,
}) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const account = useSelector((state: RootState) => state.account);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);

  const pinUserHandler = () => {
    if (account.isAuthenticated) {
      setLoading(true);
      pinInfluencer(influencerData.id_str)
        .then((response) => {
          setLoading(false);
          if (response.status === 201) {
            dispatch(fetchPinnedInfluencers());
            setIsVisible(false);
            setClickedInfluencerData(null);
            toast.success("Influencer pinned successfully");
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoginAlertModal(true);
    }
  };

  const unpinUserHandler = () => {
    setLoading(true);
    removeInfluencerPin(influencerData.id_str)
      .then((response) => {
        setLoading(false);
        if (response.status === 204) {
          dispatch(fetchPinnedInfluencers());
          setIsVisible(false);
          setClickedInfluencerData(null);
          toast.success("Influencer has been successfully unpinned");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {isVisible && (
        <Box
          className={`${styles.popover} top-zindex`}
          sx={{
            left: position === "left" ? "-100%" : "auto",
            right: position === "right" ? "-100%" : "auto",
          }}
        >
          <Slide direction="down" duration={900}>
            <Button
              disabled={loading ? true : false}
              variant="text"
              size="small"
              startIcon={
                isPinned ? (
                  <PiPushPinSimpleSlashFill size={16} />
                ) : (
                  <RiPushpin2Fill size={16} />
                )
              }
              sx={{
                flexDirection: position === "right" ? "row" : "row-reverse",
                //@ts-ignore
                color: theme.palette.common.whiteToGreen,
              }}
              onClick={isPinned ? unpinUserHandler : pinUserHandler}
            >
              {isPinned ? "Unpin" : "Pin"}
            </Button>
          </Slide>

          <Slide direction="down" duration={1000}>
            <Button
              variant="text"
              size="small"
              startIcon={<FaXTwitter size={14} />}
              sx={{
                flexDirection: position === "right" ? "row" : "row-reverse",
                //@ts-ignore
                color: theme.palette.common.whiteToGreen,
              }}
            >
              <a
                href={`https://twitter.com/${influencerData.screen_name}`}
                target="_blank"
                style={{
                  //@ts-ignore
                  color: theme.palette.common.whiteToGreen,
                  width: "100%",
                  textAlign: position === "right" ? "left" : "right",
                }}
              >
                X (Twitter)
              </a>
            </Button>
          </Slide>

          <Slide direction="down" duration={1100}>
            <Button
              variant="text"
              size="small"
              startIcon={<IoShareSocialOutline size={16} />}
              sx={{
                flexDirection: position === "right" ? "row" : "row-reverse",
                //@ts-ignore
                color: theme.palette.common.whiteToGreen,
              }}
              onClick={() => setOpen(true)}
            >
              Share
            </Button>
          </Slide>
        </Box>
      )}
      <BottomDrawer open={open} setOpen={setOpen}>
        <Box className={styles.shareContainer}>
          <Share
            facebookUrl={`https://twitter.com/${influencerData.screen_name}`}
            telegramUrl={`https://twitter.com/${influencerData.screen_name}`}
            gmailUrl={`https://twitter.com/${influencerData.screen_name}`}
            twitterUrl={`https://twitter.com/${influencerData.screen_name}`}
          />
          <Typography
            variant="body2"
            align="center"
            pt={2}
            color={theme.palette.text.primary}
          >
            Share this profile with the social media users
          </Typography>
        </Box>
      </BottomDrawer>
      {openLoginAlertModal && (
        <LoginAlertModal
          open={openLoginAlertModal}
          setOpen={setLoginAlertModal}
        />
      )}
    </>
  );
};

export default InfluencerPopover;
