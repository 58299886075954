import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { InitialAgentState } from "./interfaces";

const initialState: InitialAgentState = {
  agentHistory: null,
  agentList: [],
  openDrawer: false,
  agentResponse: null,
  agentResponseLoading: false,
  isResponseComplete: true,
  agentHistoryLoading: false,
  modalIsResponseComplete: true,
  modalAgentList: [],
  modalAgentSelectedModel: null,

  agentComponentsData: {
    signal: null,
    top_influencers: null,
    tweets: null,
  },
  modalComponentsData: {
    signal: null,
    top_influencers: null,
    tweets: null,
  },

  error: null,
};

export const fetchSingleAgentHistory = (history_item: any) => {
  const url = `/agents/messages-of-session/?session_id=${history_item}`;
  return http
    .get(url)
    .then((response) => {
      console.log(response.data.response.data);
      return response.data.response.data;
    })

    .catch((err) => {});
};
export const fetchTopTweets = () => {
  const url = `/crypto/get-top-tweets/`;
  return http
    .get(url)
    .then((response) => response.data.response.data)
    .catch((error) => error.response);
};

export const fetchCoinsStatistics = () => {
  const url = `/crypto/get-coins-statistics/?page_size=100`;
  return http
    .get(url)
    .then((response) => response.data.response.data)
    .catch((error) => error.response);
};

export const fetchAgentSps = (crypto: number) => {
  const url = `/crypto/get-influencer-signal/?page=1`;
  return http
    .post(url, {
      coin_id: crypto,
    })
    .then((response) => {
      return response.data.response.data;
    })
    .catch((err) => {});
};

export const fetchInfluencers = (type: string) => {
  const url = `/crypto/sort_influencers/?signal_type=${type}`;

  return http
    .get(url)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const fetchAgentInfluencers = (crypto: number) => {
  const url = `/crypto/get_top_influencers_coin/?coin_id=${crypto}`;
  return http
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};

export const fetchAgentHistory = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("new agent history", async (_, thunkAPI) => {
  try {

    const url = `agents/latest-chat-of-each-session/`;

    const response = await http.get(url);
    const data = response.data.response.data;
    console.log(data);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchAgentResponse = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("new agent response", async (requestData, thunkAPI) => {
  try {
    const url = "/agents/query-submit/";
    const response = await http.post(url, requestData?.body, {
      headers: { "X-Refresh-Page": requestData?.isX_Page_Refresh?.toString() },
    });
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const agentSlice = createSlice({
  name: "new agent",
  initialState,
  reducers: {
    toggleAgentDrawer: (state) => {
      state.openDrawer = !state.openDrawer;
    },
    openAgentDrawer: (state) => {
      state.openDrawer = true;
    },
    closeAgentDrawer: (state) => {
      state.openDrawer = false;
    },
    updateAgentList: (state, action) => {
      state.agentList = action.payload;
    },

    setIsResponseComplete: (state, action) => {
      state.isResponseComplete = action.payload;
    },

    updateModalAgentList: (state, action) => {
      state.modalAgentList = action.payload;
    },
    setModalSelectedModel: (state, action) => {
      state.modalAgentSelectedModel = action.payload;
    },
    setIsModalResponseComplete: (state, action) => {
      state.modalIsResponseComplete = action.payload;
    },

    setAgentComponentsData: (state, action) => {
      state.agentComponentsData = action.payload;
    },
    setModalComponentsData: (state, action) => {
      state.modalComponentsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentResponse.pending, (state) => {
        state.agentResponseLoading = true;
        state.error = null;
      })
      .addCase(fetchAgentResponse.fulfilled, (state, action) => {
        state.agentResponseLoading = false;
        state.agentResponse = action.payload;
      })
      .addCase(fetchAgentResponse.rejected, (state, action) => {
        state.agentResponseLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchAgentHistory.pending, (state) => {
        state.agentHistoryLoading = true;
        state.error = null;
      })
      .addCase(fetchAgentHistory.fulfilled, (state, action) => {
        state.agentHistoryLoading = false;
        state.agentHistory = action.payload;
      })
      .addCase(fetchAgentHistory.rejected, (state, action) => {
        state.agentHistoryLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const {
  toggleAgentDrawer,
  openAgentDrawer,
  closeAgentDrawer,
  updateAgentList,
  setIsResponseComplete,
  updateModalAgentList,
  setModalSelectedModel,
  setIsModalResponseComplete,
  setAgentComponentsData,
  setModalComponentsData,
} = agentSlice.actions;
export default agentSlice.reducer;
