import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../components/layout/header";
import Footer from "../../components/footer";
import Agent from "../../components/layout/agent";
import ResponsiveMenu from "../../components/layout/responsiveMenu";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { fetchAvailableCoins } from "../../features/crypto/cryptosSlice";
import { LayoutProps } from "../interfaces";
const { Helmet } = require("react-helmet");

const DashboardLayout: React.FC<LayoutProps> = ({
  children,
  title,
  description,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch: AppDispatch = useAppDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const userState: any = useSelector((state: RootState) => state.account);
  const isAgentPage = location.pathname.includes("/agents");

  const [openDrawer, setOpenDrawer] = useState<boolean>(
    downLg ? false : location.pathname.includes("/agents") ? true : false
  );

  useEffect(() => {
    //@ts-ignore
    if (!coinsList.availableCoins) {
      dispatch(fetchAvailableCoins());
    }
  }, []);

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        <meta
          name="description"
          content={
            description ?? "AI powered social intelligence for crypto traders"
          }
        />
      </Helmet>
      <Fade>
        <Box
          style={{
            padding: 0,
            position: "relative",
            zIndex: 10,
            paddingBottom: downLg ? "3.5rem" : 0,
          }}
        >
          <Header setOpenDrawer={setOpenDrawer} />
          <Box
            sx={{
              flex: 1,
              position: "relative",
              padding: "20px 0 0",
              zIndex: 100,
            }}
          >
            <Box
              ml={downLg ? 0 : openDrawer ? "270px" : 0}
              style={{ minHeight: "800px" }}
            >
              {children}
            </Box>
          </Box>

          {!isAgentPage && userState?.isAuthenticated && <Agent />}
          <Footer />
          {downLg && <ResponsiveMenu />}
        </Box>
      </Fade>
    </>
  );
};

export default DashboardLayout;
