import { Box, Typography, useTheme } from "@mui/material";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { AccountHeaderProps } from "../interfaces";
import { MdAccountCircle } from "react-icons/md";

const AccountHeader: React.FC<AccountHeaderProps> = ({
  profileImage,
  userFullname,
  userInfo,
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          width: "90px",
          height: "90px",
          borderRadius: "50%",
          overflow: "hidden",
          marginTop: "16px",
          marginBottom: 0,
        }}
      >
        {/*<img*/}
        {/*  src={profileImage ?? UserPlaceholder}*/}
        {/*  alt="user"*/}
        {/*  style={{*/}
        {/*    width: "100%",*/}
        {/*    height: "100%",*/}
        {/*    objectFit: "cover",*/}
        {/*  }}*/}
        {/*/>*/}
        <MdAccountCircle
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            color:
              theme.palette.mode === "light"
                ? //@ts-ignore
                  theme.palette.primary.main
                : //@ts-ignore
                  theme.palette.grey["700"],
          }}
        />
      </Box>
      <Typography
        variant="body2"
        component="h1"
        color={theme.palette.text.primary}
        sx={{
          lineHeight: 1,
          marginTop: ".5rem !important",
        }}
      >
        {!userInfo.data ? (
          <BiDotsHorizontalRounded style={{ margin: 0 }} />
        ) : userFullname === " " ? (
          "User"
        ) : (
          userFullname
        )}
      </Typography>
    </>
  );
};

export default AccountHeader;
