import { useRef } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { agentsList } from "../../../data/agentsList";
import AgentInfo from "../agentInfo";
import styles from "../agents.module.scss";

const AgentList = ({
  isModal = false,
  agents_ref,
  selectedAgentItem,
  setSelectedAgent,
  selectedAgent,
  scrollToItem,
  file,
  setFile,
  showCompleteInfo,
  setShowCompleteInfo,
}: {
  isModal?: boolean;
  agents_ref: any;
  selectedAgentItem: any;
  setSelectedAgent: any;
  selectedAgent: any;
  scrollToItem?: any;
  file?: any;
  setFile?: any;
  showCompleteInfo?: any;
  setShowCompleteInfo?: any;
}) => {
  const theme = useTheme();
  const agent_ref = useRef<(HTMLDivElement | null)[]>([]);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      ref={agents_ref}
      className={styles.agentsList}
      sx={{
        gap: downLg ? ".8rem" : "0.3rem",
        gridTemplateColumns: "repeat(7, 1fr)",
      }}
    >
      {agentsList.map((agent: any, idx: number) => (
        <Box
          key={agent.id}
          sx={{ position: "relative" }}
          //@ts-ignore
          ref={(el) => (agent_ref.current[idx] = el)}
        >
          <Box
            className={styles.agent}
            onClick={() => {
              setSelectedAgent(agent.agent_name);
              scrollToItem(
                downLg ? agent_ref.current[idx] : agents_ref.current
              );
            }}
            sx={{
              cursor: "pointer",
              background: (theme: any) => theme.palette.common.agentBg,
              border: `2px solid ${
                selectedAgent === agent.agent_name
                  ? theme.palette.primary.main
                  : "transparent"
              }`,
              boxShadow:
                selectedAgent === agent.agent_name
                  ? `0 2px 6px ${theme.palette.primary.main}`
                  : "none",
            }}
          >
            <Box className={styles.agentCardHeader}>
              <img src={agent.icon} alt={agent.agent_name} />
              <Typography
                variant="caption"
                sx={{
                  background:
                    agent.type === "Free"
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                }}
              >
                {agent.type}
              </Typography>
            </Box>
            <Typography
              variant="h6"
              component="h3"
              color={theme.palette.text.primary}
            >
              {agent.title}
            </Typography>
            <Typography
              variant="body2"
              fontWeight={300}
              color={
                theme.palette.mode === "dark"
                  ? theme.palette.grey[400]
                  : theme.palette.grey[800]
              }
            >
              {agent.short_description}
            </Typography>
          </Box>
          {downLg && selectedAgent === agent.agent_name && (
            <Box
              className={styles.agentResponsiveInfo}
              sx={{
                background: (theme: any) =>
                  theme.palette.mode === "light"
                    ? theme.palette.common.agentBg
                    : isModal
                      ? theme.palette.common.popup
                      : theme.palette.common.chatInput,
              }}
            >
              <AgentInfo
                file={file}
                setFile={setFile}
                selectedAgent={selectedAgent}
                selectedAgentItem={selectedAgentItem}
                showCompleteInfo={showCompleteInfo}
                setShowCompleteInfo={setShowCompleteInfo}
              />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default AgentList;
