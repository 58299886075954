import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { AssetsManagementState } from "./interfaces";

export const fetchWalletsList = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("wallets list", async (_, thunkAPI) => {
  try {
    const url = `/wallets/wallet-list/`;
    const response = await http.get(url);
    const data = response.data.response.data.results;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchWalletNetworthStatistics = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("networth statistics", async (wallets, thunkAPI) => {
  try {
    const url = `/wallets/networth-statistics/`;
    const response = await http.post(url, wallets);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: AssetsManagementState = {
  wallets: null,
  networthStatistics: null,
  walletsLoading: true,
  networthStatisticsLoading: false,
  error: null,
};

export const assetsManagementSlice = createSlice({
  name: "Asset Tracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWalletsList.pending, (state) => {
        state.walletsLoading = true;
        state.error = null;
      })
      .addCase(fetchWalletsList.fulfilled, (state, action) => {
        state.walletsLoading = false;
        state.wallets = action.payload;
      })
      .addCase(fetchWalletsList.rejected, (state, action) => {
        state.walletsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchWalletNetworthStatistics.pending, (state) => {
        state.networthStatisticsLoading = true;
        state.error = null;
      })
      .addCase(fetchWalletNetworthStatistics.fulfilled, (state, action) => {
        state.networthStatisticsLoading = false;
        state.networthStatistics = action.payload;
      })
      .addCase(fetchWalletNetworthStatistics.rejected, (state, action) => {
        state.networthStatisticsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default assetsManagementSlice.reducer;
