import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme, Box, Grid, Typography, Container } from "@mui/material";
import { Form, Formik } from "formik";
import { FiSearch } from "react-icons/fi";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { CryptoListState } from "../../components/crypto/interfaces";
import { setAnalyticsSelectedCoin } from "../../features/crypto/cryptoSlice";
import Loading from "../../components/common/loading";
import Input from "../../components/form/Input";
import { getCoinImage } from "../../utils/functions";
import LoginAlertModal from "../../components/common/loginAlertModal";
import DashboardLayout from "../../layout/dashboardLayout";
import styles from "./coins.module.scss";

export default function Coins() {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();

  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const accountState: any = useSelector((state: RootState) => state.account);

  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);
  const [filteredCoins, setFilteredCoins] = useState<any | null>(null);
  const [initialValues, setInitialValues] = useState<CryptoListState>({
    coin: "",
  });

  const submitUserDataHandler = () => async () => {};

  useEffect(() => {
    if (cryptosState?.availableCoins) {
      let coinsList = [...cryptosState.availableCoins];
      if (initialValues.coin === "") {
        setFilteredCoins(cryptosState.availableCoins?.slice(0, 15));
      } else {
        const filteredCoins = coinsList.filter((coin: any) => {
          const titleLowercase = coin.name.toLowerCase();
          const labelLowercase = coin.code.toLowerCase();
          const inputValueLowercase = initialValues.coin.toLowerCase();

          return (
            titleLowercase.includes(inputValueLowercase) ||
            labelLowercase.includes(inputValueLowercase)
          );
        });
        setFilteredCoins(filteredCoins.slice(0, 15));
      }
    }
  }, [initialValues.coin, cryptosState.availableCoins]);

  const coinsSelectorHandler = (selectedCoinId: number) => {
    const selectedCoin = cryptosState.availableCoins.find(
      (crypto: any) => crypto.id == selectedCoinId
    )?.cmc_tag;

    navigate(`/prices/${selectedCoin}/`, {
      state: {
        coin_id: selectedCoinId,
      },
    });
  };

  const handleCoinClick = (coin: any) => {
    if (accountState.isAuthenticated) {
      dispatch(setAnalyticsSelectedCoin(coin.id));
      coinsSelectorHandler(coin.id);
    } else {
      const accessibleCoins = cryptosState.availableCoins
        ?.filter((coin: any, idx: number) => idx < 5)
        ?.map((coin: any) => coin.id);
      if (accessibleCoins?.includes(coin.id)) {
        dispatch(setAnalyticsSelectedCoin(coin.id));
        coinsSelectorHandler(coin.id);
      } else {
        setLoginAlertModal(true);
      }
    }
  };

  return (
    <DashboardLayout title="Coinfident | Coins">
      <Typography
        variant="h6"
        component="h1"
        align="center"
        my={2}
        sx={{ color: theme.palette.text.primary }}
      >
        Choose Asset
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            background: theme.palette.background.paper,
            borderRadius: 4,
            maxWidth: 500,
            minWidth: 360,
          }}
          py={4}
          px={2}
        >
          {cryptosState.availableCoinsLoading ? (
            <Loading />
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validateOnMount={true}
                enableReinitialize={true}
                onSubmit={submitUserDataHandler()}
              >
                {(formik) => (
                  <Form id="coin_list_form">
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <Input
                          name="coin"
                          formik={formik}
                          label="Search for a coin name"
                          hasInlineLabel={false}
                          hasInnerLabel={true}
                          icon={<FiSearch color={theme.palette.common.white} />}
                          variant="outlined"
                          initialValues={initialValues}
                          setInitialValues={setInitialValues}
                          inputTextColor={theme.palette.common.white}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <Grid container mt={2} sx={{ maxWidth: 360 }}>
                {filteredCoins && filteredCoins.length > 0 ? (
                  filteredCoins.map((coin: any) => (
                    <Grid
                      item
                      md={4}
                      xs={4}
                      key={coin.id}
                      sx={{
                        padding: "2px",
                      }}
                    >
                      <Box
                        className={styles.coin_wrapper}
                        onClick={() => handleCoinClick(coin)}
                        sx={{
                          background:
                            theme.palette.mode === "light"
                              ? theme.palette.grey[700]
                              : theme.palette.grey[900],
                        }}
                      >
                        <img src={getCoinImage(coin.id)} />
                        <Box>
                          <Typography variant="body2">{coin.code}</Typography>
                          <Typography variant="caption">{coin.name}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    variant="caption"
                    align="center"
                    color={theme.palette.common.white}
                  >
                    No coin found with this name
                  </Typography>
                )}
              </Grid>
              <Typography
                variant="body2"
                mt={3}
                color={theme.palette.common.white}
              >
                Search for more coins
              </Typography>
            </>
          )}
        </Box>

        {openLoginAlertModal && (
          <LoginAlertModal
            open={openLoginAlertModal}
            setOpen={setLoginAlertModal}
          />
        )}
      </Box>
    </DashboardLayout>
  );
}
