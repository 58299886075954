import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { InfluencersPerformanceTableProps } from "../interfaces";
import { getUserProfile } from "../../../utils/userPhoto";
import { RiPushpin2Line, RiPushpin2Fill } from "react-icons/ri";
import { pinInfluencer, removeInfluencerPin } from "../../../api/influencers";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { fetchPinnedInfluencers } from "../../../features/influencers/influencersSlice";
import LoginAlertModal from "../../common/loginAlertModal";

const tableHeadData = [
  { title: "Influencer" },
  { title: "Follower" },
  { title: "Following" },
  { title: "Post Count" },
  { title: "Buy" },
  { title: "Sell" },
];

const InfluencersPerformanceTable: React.FC<
  InfluencersPerformanceTableProps
> = ({ data, pinnedList }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const accountState = useSelector((state: RootState) => state.account);

  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);
  const [selectedColumn, setSelectedColumn] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [loading, setLoading] = useState<boolean>(false);

  const scrollbarClassName: string =
    theme.palette.mode === "light"
      ? "customScrollbar-light"
      : "customScrollbar-dark";

  const pinnedListId = pinnedList?.map((influencer) => influencer.id_str);

  const hanldePinInfluencer = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    e.stopPropagation();
    const isPinned = pinnedListId?.includes(id);
    if (accountState.isAuthenticated) {
      if (isPinned) {
        setLoading(true);
        removeInfluencerPin(id)
          .then((response) => {
            if (response.status === 204) {
              dispatch(fetchPinnedInfluencers());
              setLoading(false);
              toast.success("Influencer has been successfully unpinned");
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Something went wrong");
          });
      } else {
        setLoading(true);
        pinInfluencer(id)
          .then((response) => {
            if (response.status === 201) {
              dispatch(fetchPinnedInfluencers());
              setLoading(false);
              toast.success("Influencer pinned successfully");
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Something went wrong");
          });
      }
    } else {
      setLoginAlertModal(true);
    }
  };

  const handleSortIcon = (column: string): JSX.Element => {
    let icon = <i></i>;

    if (selectedColumn === column) {
      if (order === "asc") {
        icon = <BiChevronUp size={18} />;
      } else {
        icon = <BiChevronDown size={18} />;
      }
    }

    return icon;
  };

  const handleSort = (cell: string): void => {
    setSelectedColumn(cell);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  const handlePinnedListTotop = (data: any) => {
    if (pinnedList) {
      const removePinned = data?.filter(
        (influencer: any) => !pinnedListId?.includes(influencer.id_str)
      );

      return [...pinnedList, ...removePinned];
    } else {
      return data;
    }
  };

  const sortedData = useMemo(() => {
    const copyData = [...data];

    switch (selectedColumn) {
      case "Influencer":
        return handlePinnedListTotop(
          copyData.sort((a, b) =>
            order === "asc"
              ? a.name.localeCompare(b.name)
              : b.name.localeCompare(a.name)
          )
        );
      case "Follower":
        return handlePinnedListTotop(
          copyData.sort((a, b) =>
            order === "asc"
              ? a.followers_count - b.followers_count
              : b.followers_count - a.followers_count
          )
        );
      case "Following":
        return handlePinnedListTotop(
          copyData.sort((a, b) =>
            order === "asc"
              ? a.friends_count - b.friends_count
              : b.friends_count - a.friends_count
          )
        );
      case "Post Count":
        return handlePinnedListTotop(
          copyData.sort((a, b) =>
            order === "asc"
              ? a.statuses_count - b.statuses_count
              : b.statuses_count - a.statuses_count
          )
        );
      default:
        return handlePinnedListTotop(copyData);
    }
  }, [selectedColumn, order, pinnedList]);

  return (
    <>
      <TableContainer
        className={`customScrollbar ${scrollbarClassName}`}
        sx={{ maxHeight: 800 }}
      >
        <Table stickyHeader>
          <TableHead sx={{ zIndex: 3 }}>
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography color={theme.palette.text.primary}>
                  Details
                </Typography>
              </TableCell>
              <TableCell colSpan={2} align="center">
                <Typography color={theme.palette.text.primary}>
                  Signal Performance
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {tableHeadData.map((cell) => (
                <TableCell
                  key={cell.title}
                  align="center"
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleSort(cell.title)}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography color={theme.palette.text.primary}>
                      {cell.title}
                    </Typography>
                    {handleSortIcon(cell.title)}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((influencer: any) => (
              <TableRow
                key={influencer.id_str}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="left"
                  onClick={() =>
                    navigate(`/influencers/${influencer.screen_name}`, {
                      state: { id: influencer.id_str },
                    })
                  }
                >
                  <Box display="flex" alignItems="center">
                    <IconButton
                      disabled={loading}
                      onClick={(e) => hanldePinInfluencer(e, influencer.id_str)}
                    >
                      {pinnedListId?.includes(influencer.id_str) ? (
                        <RiPushpin2Fill size={20} />
                      ) : (
                        <RiPushpin2Line size={20} />
                      )}
                    </IconButton>
                    <Avatar
                      src={getUserProfile(influencer?.screen_name)}
                      sx={{ width: 50, height: 50, mx: 1 }}
                    />
                    <Stack>
                      <Typography
                        color={theme.palette.text.primary}
                        variant="body2"
                      >
                        {influencer?.name}
                      </Typography>
                      <Typography
                        color={theme.palette.text.primary}
                        variant="caption"
                      >
                        @{influencer?.screen_name}
                      </Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={theme.palette.text.primary}
                    variant="caption"
                  >
                    {Number(influencer?.followers_count).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={theme.palette.text.primary}
                    variant="caption"
                  >
                    {Number(influencer?.friends_count).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={theme.palette.text.primary}
                    variant="caption"
                  >
                    {Number(influencer?.statuses_count).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={theme.palette.text.primary}
                    variant="caption"
                  >
                    {Number(influencer?.friends_count).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={theme.palette.text.primary}
                    variant="caption"
                  >
                    {Number(influencer?.statuses_count).toLocaleString()}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openLoginAlertModal && (
        <LoginAlertModal
          open={openLoginAlertModal}
          setOpen={setLoginAlertModal}
        />
      )}
    </>
  );
};

export default InfluencersPerformanceTable;
