import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FaListUl, FaTh } from "react-icons/fa";
import { InfluencerTitleProps } from "../interfaces";

const InfluencerTitle: React.FC<InfluencerTitleProps> = ({
  icon,
  title,
  info,
  selectedDate,
  setSelectedDate,
  view,
  setView,
}) => {
  const theme = useTheme();

  const date_items = [
    { key: "3D", value: 3 },
    { key: "7D", value: 7 },
    { key: "2W", value: 14 },
    { key: "1M", value: 30 },
  ];

  const handleChangeDate = (
    e: React.MouseEvent<HTMLElement>,
    type: 3 | 7 | 14 | 30 | null
  ): void => {
    if (type !== null) {
      setSelectedDate(type);
    }
  };

  const handleChangeView = (
    e: React.MouseEvent<HTMLElement>,
    type: "tile" | "table" | null
  ): void => {
    if (type !== null) {
      setView(type);
    }
  };

  return (
    <Box
      p={1}
      sx={{
        background: (theme: any) => theme.palette.common.darkPrimary,
        width: "100%",
        minHeight: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center">
        {icon}
        <Box>
          <Typography
            component="h1"
            variant="caption"
            sx={{
              flex: 1,
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            {title}
          </Typography>
          {info && (
            <Typography
              component="h2"
              variant="caption"
              sx={{
                fontWeight: 100,
                fontSize: "12.5px",
              }}
            >
              {info}
            </Typography>
          )}
        </Box>
      </Stack>
      {view && (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <ToggleButtonGroup
            value={view}
            exclusive
            size="small"
            onChange={handleChangeView}
            sx={{
              "& .Mui-selected": {
                backgroundColor: `#00977440 !important`,
                "& svg": {
                  color: `${theme.palette.secondary.light} !important`,
                },
              },
            }}
          >
            <ToggleButton
              value="table"
              sx={{
                "&:hover": {
                  backgroundColor: `#00977440`,
                },
              }}
            >
              <FaListUl size={16} color={theme.palette.common.white} />
            </ToggleButton>
            <ToggleButton
              value="tile"
              sx={{
                "&:hover": {
                  backgroundColor: `#00977440`,
                },
              }}
            >
              <FaTh size={16} color={theme.palette.common.white} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      )}

      {selectedDate && (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <ToggleButtonGroup
            value={selectedDate}
            exclusive
            size="small"
            onChange={handleChangeDate}
            sx={{
              "& .Mui-selected": {
                backgroundColor: `#00977440 !important`,
                "& span": {
                  color: `${theme.palette.secondary.light} !important`,
                },
              },
            }}
          >
            {date_items.map((date_item) => (
              <ToggleButton
                key={date_item.value}
                value={date_item.value}
                sx={{
                  "&:hover": {
                    backgroundColor: `#00977440`,
                  },
                }}
              >
                <Typography
                  variant="caption"
                  color={theme.palette.common.white}
                >
                  {date_item.key}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
      )}
    </Box>
  );
};

export default InfluencerTitle;
