import React, { useMemo } from "react";
import { AgentChartProps, ChartType } from "../../interfaces";
import { isEmpty } from "lodash";
import AgentCustomLine from "../../../charts/AgentCustomLine";
import { Box, Skeleton } from "@mui/material";

const chartColors = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
];

const chartNameConverter = (name: string) => {
  switch (name) {
    case "average_sps":
      return "Average SPS";
    case "average_technical":
      return "Average Technical";
    case "tweet_count":
      return "Number of Tweets";
    case "positive_sentiment":
      return "Positive Sentiment";
    case "total_engagement":
      return "Total Engagement";
    case "price":
      return "Price";
    default:
      return name;
  }
};

const fixNumber = (numbers: any) => {
  return numbers.map((num: number) => Number(num).toFixed(3));
};

const AgentChart: React.FC<AgentChartProps> = ({
  data,
  loading,
  responseIndex,
}) => {
  const handleChartData = useMemo(() => {
    const { sps_chart } = data;
    const { infs_chart } = data;

    let availableData: any = [];
    let availableDate: any = [];
    let availableCoin: any = [];
    let availableNames: any = [];
    let colorIndex = 0;

    const createChartData = (key: string, data: any) => {
      if (
        (key === "coin_names" || key === "coin_name") &&
        !isEmpty(data[key])
      ) {
        availableCoin = data[key];
      } else if (key === "datetime" && !isEmpty(data[key])) {
        availableDate = data[key];
      } else if (!isEmpty(data[key])) {
        if (data[key].every((i: any) => Array.isArray(i))) {
          if (data[key].some((i: any) => !isEmpty(i))) {
            const dataStructure = data[key].map((i: any, index: number) => ({
              name: data?.coin_names?.at(index),
              data: fixNumber(i),
              color: chartColors[colorIndex++ % chartColors.length],
            }));

            availableNames?.push(chartNameConverter(key));
            availableData?.push(dataStructure);
          }
        } else {
          const dataStructure = [
            {
              name: chartNameConverter(key),
              data: fixNumber(data[key as keyof ChartType]),
              color: chartColors[colorIndex++ % chartColors.length],
            },
          ];

          availableNames?.push(chartNameConverter(key));
          availableData?.push(dataStructure);
        }
      }
    };

    for (const key in sps_chart) {
      createChartData(key, sps_chart);
    }

    for (const key in infs_chart) {
      createChartData(key, infs_chart);
    }

    return {
      series: availableData,
      date: availableDate,
      coins: availableCoin,
      names: availableNames,
    };
  }, [data]);

  const hasChart = useMemo(() => {
    const { sps_chart } = data;
    const { infs_chart } = data;
    const { price_chart } = data;

    if (
      isEmpty(sps_chart?.datetime) &&
      isEmpty(infs_chart?.datetime) &&
      isEmpty(price_chart?.datetime)
    ) {
      return false;
    } else {
      return true;
    }
  }, []);

  return (
    <>
      {hasChart && loading[responseIndex] ? (
        <Box mt={4}>
          <Skeleton variant="rounded" height={200} sx={{ width: "100%" }} />
        </Box>
      ) : (
        <AgentCustomLine
          data={handleChartData.series}
          labels={handleChartData.date}
          names={handleChartData.names}
        />
      )}
    </>
  );
};

export default AgentChart;
