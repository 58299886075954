import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { RiPushpin2Fill } from "react-icons/ri";
import { MdMoreVert } from "react-icons/md";
import InfluencerPopover from "../influencerPopover";
import Avatar from "../../common/avatar";
import { getUserBanner } from "../../../utils/userPhoto";
import { InfluencerCardProps } from "../interfaces";
import styles from "../influencers.module.scss";

const InfluencerCard: React.FC<InfluencerCardProps> = ({
  influencerData,
  isVisible,
  setIsVisible,
  clickedInfluencerData,
  setClickedInfluencerData,
  isPinned,
}) => {
  const theme = useTheme();
  const infRef = useRef(null);

  const [popoverPosition, setPopoverPosition] = useState("right");

  const moreButtonClicked = (influencer: any) => {
    setIsVisible(!isVisible);
    setClickedInfluencerData(influencer);

    //@ts-ignore
    const rect = infRef.current.getBoundingClientRect();
    if (rect.left < window.innerWidth / 2) {
      setPopoverPosition("right");
    } else {
      setPopoverPosition("left");
    }
  };

  return (
    <Box
      className={`${
        clickedInfluencerData &&
        influencerData.id_str === clickedInfluencerData.id_str
          ? "top-zindex"
          : ""
      } ${styles.influencerCardWrapper}`}
    >
      <Box
        ref={infRef}
        sx={{
          background:
            theme.palette.mode === "light"
              ? `linear-gradient(220deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, .8) 70%, rgba(255, 255, 255, 1) 100%),
          url(${getUserBanner(influencerData.screen_name)}), ${
            //@ts-ignore
            theme.palette.common.green
          } 50% / contain no-repeat`
              : `linear-gradient(220deg, rgba(0, 0, 0, 0) 0%, #000 68.93%),
      url(${getUserBanner(influencerData.screen_name)}), ${
        //@ts-ignore
        theme.palette.common.green
      } 50% / contain no-repeat`,
        }}
        className={styles.influencerCard}
      >
        <Link
          to={`/influencers/${influencerData.screen_name}`}
          state={{ id: influencerData.id_str }}
        >
          <Avatar
            screenName={influencerData.screen_name}
            width={36}
            height={36}
          />
          <Box className={styles.influencerCardContent}>
            <Typography
              variant="body2"
              component="h1"
              sx={{ color: theme.palette.text.primary }}
            >
              {influencerData.name.slice(0, 12)}
              {influencerData.name.length > 12 && "..."}
            </Typography>
            <Typography
              variant="caption"
              component="h2"
              sx={{ color: theme.palette.text.primary }}
            >
              @{influencerData.screen_name}
            </Typography>
          </Box>
        </Link>
        <IconButton
          aria-label="More"
          size="small"
          className={styles.moreButton}
          onClick={() => moreButtonClicked(influencerData)}
        >
          <MdMoreVert size={16} color={theme.palette.common.white} />
        </IconButton>
        {isPinned && (
          <RiPushpin2Fill
            size={16}
            className={styles.pinStatus}
            color={theme.palette.common.white}
          />
        )}
      </Box>
      {clickedInfluencerData &&
      influencerData.id_str === clickedInfluencerData.id_str ? (
        <InfluencerPopover
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          position={popoverPosition}
          influencerData={clickedInfluencerData}
          setClickedInfluencerData={setClickedInfluencerData}
          isPinned={isPinned ? true : false}
        />
      ) : null}
    </Box>
  );
};

export default InfluencerCard;
